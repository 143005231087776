import React, { useState, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Route, Routes, useNavigate } from 'react-router-dom';

import './menu.css'
import toast from 'react-hot-toast';

import Loader from '../init/loader';


import {
  BiMenu, BiGridAlt, BiCartAlt, BiBox, BiGroup, BiArchiveIn,
  BiCog, BiLogOut, BiFile, BiBriefcase
} from "react-icons/bi";

import { BsCartPlus } from "react-icons/bs";
import { PiDevToLogo } from "react-icons/pi";
import { VscTools } from "react-icons/vsc";

import { DataContext } from '../init/getData';

import Dashboard from '../dashboard/dashboard';
import ProductTab from '../productTab/productTab';
import SettingTab from '../settings/settingTab';
import AccountTab from '../accountTab/accountTab';
import NewSale from '../newSale/newSale';
import TablesTab from '../tablesTab/tableTab';
import SalesTab from '../salesTab/salesTab';
import TransactionsTab from '../transactionsTab/transactionTab';
import ReportTab from '../reportTab/reportTab';
import ToolTab from '../toolTab/toolTab';
import DevTab from '../devTab/devTab';

const type = 'electron';

const DashboardLazy = React.lazy(() => import('../dashboard/dashboard'))
const ProductTabLazy = React.lazy(() => import('../productTab/productTab'))
const SettingTabLazy = React.lazy(() => import('../settings/settingTab'))

const AccountTabLazy = React.lazy(() => import('../accountTab/accountTab'))
const SalesLazy = React.lazy(() => import('../newSale/newSale'))
const TablesTabLazy = React.lazy(() => import('../tablesTab/tableTab'))
const SalesTabLazy = React.lazy(() => import('../salesTab/salesTab'))
const TransactionsTabLazy = React.lazy(() => import('../transactionsTab/transactionTab'))
const ReportTabLazy = React.lazy(() => import('../reportTab/reportTab'))
const ToolTabLazy = React.lazy(() => import('../toolTab/toolTab'))




function Menu(props) {


  const { settingData, userData, setAccess, expireyDate, setLoaderEffect, devData } = useContext(DataContext);


  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role;

  const [toggle, setToggle] = useState('sidebar');
  const [tog, setTog] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    setLoaderEffect(false)
  }, []);


  let DashboardComponent, ProductTabComponent, SettingTabComponent, AccountTabComponent, ToolTabComponent,
  SalesComponent, TablesTabComponent, SalesTabComponent, TransactionsTabComponent, ReportTabComponent, DevTabComponent;

if (devData.isWebApp) {
  DashboardComponent = DashboardLazy;
  ProductTabComponent = ProductTabLazy;
  SettingTabComponent = SettingTabLazy;
  AccountTabComponent = AccountTabLazy;
  SalesComponent = SalesLazy;
  TablesTabComponent = TablesTabLazy;
  SalesTabComponent = SalesTabLazy;
  TransactionsTabComponent = TransactionsTabLazy;
  ReportTabComponent = ReportTabLazy;
  ToolTabComponent = ToolTabLazy;
  DevTabComponent = DevTab;
} else {
  DashboardComponent = Dashboard;
  ProductTabComponent = ProductTab;
  SettingTabComponent = SettingTab;
  AccountTabComponent = AccountTab;
  SalesComponent = NewSale;
  TablesTabComponent = TablesTab;
  SalesTabComponent = SalesTab;
  TransactionsTabComponent = TransactionsTab;
  ReportTabComponent = ReportTab;
  ToolTabComponent = ToolTab;
  DevTabComponent = DevTab;
}


  const toggles = () => {
    if (tog == 1) {
      setTog(0);
      setToggle('sidebar open')
    }
    else {
      setTog(1);
      setToggle('sidebar')
    }
  }

  const signOut = () => {
    toast.success('Logged out');
    navigate('/');
    setAccess(false);
  }

  return (
    <div>
      <div className={toggle}>
        <div className="logo-details">
          <i className='bx'></i>
          <div className="logo_name">Riser</div>
          <i className='bx' id="btn" onClick={toggles}><BiMenu /></i>
        </div>
        <ul className="nav-list">

          {userRole.dashboardTab &&
            <li>
              <NavLink to="dashboard" className={({ isActive }) => (isActive ? "active-style" : 'none')} >
                <i className='bx'><BiGridAlt /></i>
                <span className="links_name">Dashboard</span>
              </NavLink>
              <span className="tooltip">Dashboard</span>
            </li>
          }

          {userRole.salesTab &&
            <li>
              <NavLink to="newSale" className={({ isActive }) => (isActive ? "active-style" : 'none')} >
                <i className='bx' ><BiCartAlt /></i>
                <span className="links_name">new Sale</span>
              </NavLink>
              <span className="tooltip">new Sale</span>
            </li>
          }

          {userRole.deliveryTab &&
            <li>
              <NavLink to="tablesTab" className={({ isActive }) => (isActive ? "active-style" : 'none')} >
                <i className='bx' ><BiCartAlt /></i>
                <span className="links_name">Tables</span>
              </NavLink>
              <span className="tooltip">Tables</span>
            </li>
          }

          {userRole.orderTab &&
            <li>
              <NavLink to="orderTab" className={({ isActive }) => (isActive ? "active-style" : 'none')}>
                <i className='bx' ><BiArchiveIn /></i>
                <span className="links_name">Sales</span>
              </NavLink>
              <span className="tooltip">Sales</span>
            </li>
          }

          {userRole.deliveryTab &&
            <li>
              <NavLink to="delivery" className={({ isActive }) => (isActive ? "active-style" : 'none')}>
                <i className='bx' ><BiArchiveIn /></i>
                <span className="links_name">Delivery</span>
              </NavLink>
              <span className="tooltip">Delivery</span>
            </li>
          }

          {userRole.productTab &&
            <li>
              <NavLink to="productTab" className={({ isActive }) => (isActive ? "active-style" : 'none')} >
                <i className='bx'><BiBox /></i>
                <span className="links_name">Products</span>
              </NavLink>
              <span className="tooltip">Products</span>
            </li>
          }

          {userRole.clientTab &&
            <li>
              <NavLink to="accountTab" className={({ isActive }) => (isActive ? "active-style" : 'none')}>
                <i className='bx'><BiGroup /></i>
                <span className="links_name">Accounts</span>
              </NavLink>
              <span className="tooltip">Accounts</span>
            </li>
          }

          {userRole.accountingTab &&
            <li>
              <NavLink to="transactionsTab" className={({ isActive }) => (isActive ? "active-style" : 'none')}>
                <i className='bx'><BiBriefcase /></i>
                <span className="links_name">Transactions</span>
              </NavLink>
              <span className="tooltip">Transactions</span>
            </li>
          }

          {userRole.reportTab &&
            <li>
              <NavLink to="reportTab" className={({ isActive }) => (isActive ? "active-style" : 'none')}>
                <i className='bx'><BiFile /></i>
                <span className="links_name">Reports</span>
              </NavLink>
              <span className="tooltip">Reports</span>
            </li>
          }

          {userRole.settingTab &&
            <li>
              <NavLink to="toolTab" className={({ isActive }) => (isActive ? "active-style" : 'none')}>
                <i className='bx'><VscTools /></i>
                <span className="links_name">Tools</span>
              </NavLink>
              <span className="tooltip">Tools</span>
            </li>
          }


          {userRole.settingTab &&
            <li>
              <NavLink to="settingTab" className={({ isActive }) => (isActive ? "active-style" : 'none')}>
                <i className='bx' ><BiCog /></i>
                <span className="links_name">Settings</span>
              </NavLink>
              <span className="tooltip">Settings</span>
            </li>
          }


          {userId == 777 &&
            <li>
              <NavLink to="devTab" className={({ isActive }) => (isActive ? "active-style" : 'none')}>
                <i className='bx' ><PiDevToLogo /></i>
                <span className="links_name">Dev </span>
              </NavLink>
              <span className="tooltip">Dev </span>
            </li>
          }

          <li className="profile">
            <div className="profile-details">
              <div className="name_job">
                <div className="name">User: {userName}</div>
                <div className="job">Role: {userRole.name}</div>

              </div>
            </div>
            <button onClick={signOut}>
              <i className='bx' id="log_out" ><BiLogOut /></i>
            </button>
          </li>
        </ul>
      </div>

      <section className="home-section">


        <Routes>
          <Route path='dashboard' element={<React.Suspense fallback={<Loader />}> <DashboardComponent /> </React.Suspense>} />
          <Route path='productTab/*' element={<React.Suspense fallback={<Loader />}> <ProductTabComponent /> </React.Suspense>} />
          <Route path='accountTab/*' element={<React.Suspense fallback={<Loader />}> <AccountTabComponent /> </React.Suspense>} />
          <Route path='orderTab/*' element={<React.Suspense fallback={<Loader />}> <SalesTabComponent /> </React.Suspense>} />
          <Route path='newSale' element={<React.Suspense fallback={<Loader />}> <SalesComponent /> </React.Suspense>} />
          <Route path='tablesTab/*' element={<React.Suspense fallback={<Loader />}> <TablesTabComponent /> </React.Suspense>} />
          <Route path='transactionsTab/*' element={<React.Suspense fallback={<Loader />}> <TransactionsTabComponent /> </React.Suspense>} />
          <Route path='reportTab/*' element={<React.Suspense fallback={<Loader />}> <ReportTabComponent /> </React.Suspense>} />
          <Route path='toolTab/*' element={<React.Suspense fallback={<Loader />}> <ToolTabComponent /> </React.Suspense>} />
          <Route path='settingTab/*' element={<React.Suspense fallback={<Loader />}> <SettingTabComponent /> </React.Suspense>} />
          <Route path='devTab/*' element={<React.Suspense fallback={<Loader />}> <DevTabComponent /> </React.Suspense>} />

        </Routes>

      </section>

    </div>

  );
}


export default Menu;