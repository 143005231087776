import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { DataContext } from '../../../init/getData';

import Select from 'react-select';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';


const UserAdd = (props) => {

  const { api, userData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const { showModal, toggleModal, refreshTable, tittle } = props;

  const {refreshUser, roleData, refreshAllUserData} = useContext(DataContext);
  const [role, setRole] = useState({value: '', label: ''});
  
  const handleSubmit = async (e) => {
    toggleModal();
    e.preventDefault();
    const form = document.getElementById("Form");
      
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);

    data['roleId'] = role.value; 
    
    let res = await api.post(`/user/add`, JSON.stringify(data))
    if(res.data.status){
      toast.success(res.data.message);
      refreshTable();
      refreshAllUserData();
    }else{
      toast.error(res.data.message);
    }
    
  }


      // Category Options for dropDown
      const roleOptions = roleData.map(item => ({
        value: item.id,
        label: item.name,
      }));


  return (
      <Modal show={showModal}  size="lg" onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Add {tittle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form onSubmit={handleSubmit} id='Form'>


      <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
            <Form.Label>Name</Form.Label>
            <input type="text"  name="name" id="name" className="form-control"></input>
          </Form.Group>

          {/* delivery  */}
          <Form.Group as={Col}>
          <Form.Label>Role</Form.Label>
            <Select
            options={roleOptions}
            isSearchable={true}
            placeholder="Select Role"
            value={role}
            onChange={setRole}
            />
        </Form.Group>

        </Row>


      <Row className="mb-3" style={{ marginTop: '2%' }}>

      <Form.Group as={Col}>
          <Form.Label>Email</Form.Label>
          <input type="email"  name="email" id="email" className="form-control"></input>
        </Form.Group>


        <Form.Group as={Col}>
          <Form.Label>Password</Form.Label>
          <input type="text"  name="password" id="password" className="form-control"></input>
        </Form.Group>

      </Row>

     
      
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <input type="submit" className="btn btn-primary" value="Save Data"></input>
      </div>

      </Form>
      </Modal.Body>
      </Modal>
      )
}


export default UserAdd;