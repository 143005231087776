import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useNavigate, NavLink } from 'react-router-dom';

import Transaction from './transaction/transaction';
import Invoice from './invoice/invoice';

import { FcBusinessman, FcManager, FcPortraitMode, FcAutomotive, FcInternal, FcShipped, FcDonate, FcKindle } from "react-icons/fc";


import { DataContext } from '../init/getData';

function TransactionsTab(props) {

  const { settingData, userData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role;

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('customer');

  useEffect(() => {

    navigate('/transactionsTab/customer');

  }, []);


  return (
    <div className="card" style={{ paddingTop: '0px', userSelect: 'none' }}>

      <div>


        {userRole.customerPage &&
          <NavLink to="customer" onClick={() => setActiveTab('customer')}>
            <div className={`${activeTab === 'customer' ? 'tab-one active-link' : 'tab-one'}`} style={{ width: '10%' }}>
              <FcPortraitMode size={35} />
              <span className="tab-text">Customers</span>
            </div>
          </NavLink>
        }


        {userRole.supplierPage &&
          <NavLink to="supplier" onClick={() => setActiveTab('supplier')}>
            <div className={`${activeTab === 'supplier' ? 'tab-two active-link' : 'tab-two'}`} style={{ width: '10%' }}>
              <FcBusinessman size={35} />
              <span className="tab-text">Suppliers</span>
            </div>
          </NavLink>
        }


        <NavLink to="employee" onClick={() => setActiveTab('employee')}>
          <div className={`${activeTab === 'employee' ? 'tab-three active-link' : 'tab-three'}`} style={{ width: '10%' }}>
            <FcManager size={30} />
            <span className="tab-text">Employees</span>
          </div>
        </NavLink>


        <NavLink to="expenses" onClick={() => setActiveTab('expenses')}>
          <div className={`${activeTab === 'expenses' ? 'tab-five active-link' : 'tab-five'}`} style={{ width: '10%' }} >
            <FcDonate size={30} />
            <span className="tab-text">Expenses</span>
          </div>
        </NavLink>


        <NavLink to="delivery" onClick={() => setActiveTab('delivery')}>
          <div className={`${activeTab === 'delivery' ? 'tab-two active-link' : 'tab-two'}`} style={{ width: '10%' }}>
            <FcShipped size={30} />
            <span className="tab-text">Delivery</span>
          </div>
        </NavLink>


        <NavLink to="salesInvoice" onClick={() => setActiveTab('salesInvoice')}>
          <div className={`${activeTab === 'salesInvoice' ? 'tab-four active-link' : 'tab-four'}`} style={{ width: '10%' }}>
            <FcKindle size={30} />
            <span className="tab-text">Sales Invoice</span>
          </div>
        </NavLink>

        {userRole.purchaseInvoicePage &&
          <NavLink to="purchaseInvoice" onClick={() => setActiveTab('purchaseInvoice')}>
            <div className={`${activeTab === 'purchaseInvoice' ? 'tab-six active-link' : 'tab-six'}`} style={{ width: '10%' }}>
              <FcKindle size={30} />
              <span className="tab-text">Purchase Invoice</span>
            </div>
          </NavLink>
        }

        {userRole.returnInvoicePage &&
          <NavLink to="returnInvoice" onClick={() => setActiveTab('returnInvoice')}>
            <div className={`${activeTab === 'returnInvoice' ? 'tab-seven active-link' : 'tab-seven'}`} style={{ width: '10%' }}>
              <FcKindle size={30} />
              <span className="tab-text">Return Invoice</span>
            </div>
          </NavLink>
        }

      </div>


      <Routes>

  

        <Route path='customer' element={<Transaction type={'Customer'} />} />
        <Route path='supplier' element={<Transaction type={'Supplier'} />} />
        <Route path='employee' element={<Transaction type={'Employee'} />} />
        <Route path='expenses' element={<Transaction type={'Expenses'} />} />
        <Route path='delivery' element={<Transaction type={'Delivery'} />} />

    
        <Route path='salesInvoice'    element={<Invoice type={'SalesInvoice'}    isOut={false} />} />
        <Route path='purchaseInvoice' element={<Invoice type={'PurchaseInvoice'} isOut={true} />} />
        <Route path='returnInvoice'   element={<Invoice type={'ReturnInvoice'}   isOut={false}/>} />

      </Routes>

    </div>
  );
}

export default TransactionsTab;
