import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';
import Select from 'react-select';

import { userId } from "../../../../dynamic/token";

import { DataContext } from '../../../../init/getData';


const SimpleStockTransfer = (props) => {

  const {api, wareHouseData, userData} = useContext(DataContext);
  const userId = userData.id;

  const { showModal, toggleModal, refreshTable, idRow, wareHouseId } = props;

  const [toWareHouse, setToWareHouse] = useState({});

  const [loadingEffect, setLoadingEffect] = useState(false);


  // Handle Submit 
  const handleSubmit = async (e) => {
    
    setLoadingEffect(true);
    e.preventDefault();
    const form = document.getElementById("addForm");
      
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);

    data['productId'] = idRow.id;  
    data['createdBy'] = userId;  

    data['fromWareHouse'] = wareHouseId;  
    data['toWareHouse'] = toWareHouse; 

    
    let res = await api.post(`/stock/transferSimple`, JSON.stringify(data))
    if(res.data.status){
      toast.success(res.data.message);
      toggleModal();
      form.reset();
      refreshTable();
      setToWareHouse({});
    }else{
      toast.error(res.data.message);
    }
    setLoadingEffect(false);
  }


  // WareHouse Options
  const wareHouseOption = wareHouseData.map(item => ({
    value: item.id,
    label: item.name
  }));

  const wareHouseOptions = wareHouseOption.filter(
    item => item.value !== wareHouseId.value
  );

  return (
      <Modal show={showModal} onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Transfer Simple Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form onSubmit={handleSubmit} id='addForm'>

      <div className="modal-body">


      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">toStock</label>
      <div className="col-sm-10">
      <Select
            options={wareHouseOptions}
            isSearchable={true}
            placeholder="Select"
            value={toWareHouse}
            onChange={setToWareHouse}
            />
        </div>
      </div>



      
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label">Quantity</label>
        <div className="col-sm-10">
          <input type="number" step="any" name="quantity" id="quantity" className="form-control"></input>
        </div>
      </div>


      

      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label">Details</label>
        <div className="col-sm-10">
          <textarea type="text" step="any" name="details" id="details" className="form-control"></textarea>
        </div>
      </div>





  

</div>

      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <button className="btn btn-primary" onClick={handleSubmit}>Save Data
        {loadingEffect && 
        <span className="spinner-border spinner-border-sm" style={{ marginLeft: '10px'}} role="status" aria-hidden="true"></span>
          }
        </button>
      </div>
      </form>
      </Modal.Body>
      </Modal>
      )
}


export default SimpleStockTransfer;