import React, {  useState, useEffect, createContext} from 'react';
import { Route, Routes, useNavigate, NavLink} from 'react-router-dom';

import Barcode from './barcode/barcode';
import BackUp from './backUp/backUp';

import { FcSupport, FcManager, FcExport  , FcReadingEbook, FcPrint, FcTimeline } from "react-icons/fc";
import { BiBarcodeReader } from "react-icons/bi";

function ToolTab(props) {

  const [showBackUpModal, setShowBackUpModal] = useState(false);
  const [activeTab, setActiveTab] = useState('barcode');

  const navigate = useNavigate();

  useEffect(() => {

    navigate('/toolTab/barcode');

  }, []);


  return (
    <div className="card" style={{paddingTop : '0px', userSelect: 'none'}}>
      
      <div>

        <NavLink to="barcode" onClick={() => setActiveTab('barcode')}>
          <div className={`${activeTab === 'barcode' ? 'tab-two active-link' : 'tab-two'}`}>
          <BiBarcodeReader size={30}/>
            <span className="tab-text">Barcode</span>
          </div>
        </NavLink>
        
              
        <div className="tab-seven" style={{width: '12%', cursor: 'pointer'}} onClick={() => setShowBackUpModal(!showBackUpModal)} >
          <FcExport  size={30}/>
            <span className="tab-text">Back Up</span>
          </div>

   

     


      </div>

      
      <BackUp showModal={showBackUpModal} toggleModal={() => setShowBackUpModal(!showBackUpModal)} />

 
      <Routes>
        <Route path='barcode' element= {<Barcode/>}/>
        
      </Routes>

    </div>
  );
}

export default ToolTab;
