import React, { useState, useEffect, useContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Switch, Collapse, Button } from 'antd';



import Select from 'react-select';

import { DataContext } from '../../init/getData';

const { Panel } = Collapse;

function PiSetting() {

  const { api, userData, refreshSetting, settingData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const [loadingEffect, setLoadingEffect] = useState(false);


  const [roundTo, setRoundTo] = useState('0');
  const [showSecondaryUnit, setShowSecondaryUnit] = useState(false);


  useEffect(() => {
    if (settingData) {
      setRoundTo(settingData.roundTo)
      setShowSecondaryUnit(settingData.showSecondaryUnit)
    }
  }, [settingData]);


  const handleSubmit = async (e) => {

    setLoadingEffect(true);

    let settings = {
      roundTo,
      showSecondaryUnit,
    }

    let res = await api.post(`/setting/edit`, JSON.stringify(settings))
    if (res.data.status) {
      toast.success(res.data.message);
      refreshSetting();
    }
    else {
      toast.error(res.data.message);
    }
    setLoadingEffect(false);
  }



  const defualtForm = (
    <>
      {/*  Default Date */}
      <Row className="mb-3">
        <Form.Label column sm={2}>
          Defualt Supplier
        </Form.Label>
        <Col sm={4}>
          <select className="form-control" >
            <option value='null'> none </option>
          </select>
        </Col>
      </Row>

      <Row className="mb-3">
        <Form.Label column sm={2}>
          Defualt WareHouse
        </Form.Label>
        <Col sm={4}>
          <select className="form-control">
            <option value='null'> none </option>
          </select>
        </Col>
      </Row>

      <Row className="mb-3">
        <Form.Label column sm={2}>
          Defualt Date
        </Form.Label>
        <Col sm={4}>
          <select className="form-control">
            <option value='null'> none </option>
          </select>
        </Col>
      </Row>

    </>
  )


  const costChangeForm = (
    <>
      {/*  Default Date */}
      <Row className="mb-3">
        <Form.Label column sm={2}>
          Method
        </Form.Label>
        <Col sm={4}>
          <select className="form-control" >
            <option value='null'> none </option>
          </select>
        </Col>
      </Row>

      <Row className="mb-3">
        <Form.Label column sm={2}>
          Round To
        </Form.Label>
        <Col sm={4}>
          <select className="form-control" value={roundTo} onChange={(e) => setRoundTo(e.target.value)}>
            <option value="0"> No Round </option>
            <option value="1"> 1 </option>
            <option value="0.5"> 0.5 </option>
            <option value="0.25"> 0.25 </option>
          </select>
        </Col>
      </Row>

    </>
  )


  const sellingPriceForm = (
    <>
      <Row className="mb-3">
        <Form.Label column sm={2}>
          Show Selling Price
        </Form.Label>
        <Col sm={4}>
          <select className="form-control">
            <option value='true'> Yes </option>
            <option value='false'> No </option>
          </select>
        </Col>
      </Row>

      <Row className="mb-3">
        <Form.Label column sm={2}>
          Show Raise By
        </Form.Label>
        <Col sm={4}>
          <select className="form-control">
            <option value='daily'> Yes </option>
            <option value='monthly'> No </option>
          </select>
        </Col>
      </Row>

      <Row className="mb-3">
        <Form.Label column sm={2}>Raise By %</Form.Label>
        <Col sm={4}>
          <Form.Control type="number" step='any' placeholder="%" />
        </Col>
      </Row>
    </>
  )


  const quantityForm = (
    <>
      <Row className="mb-3">
        <Form.Label column sm={2}>
          Show Secondary Unit
        </Form.Label>
        <Col sm={4}>
          <select className="form-control" value={showSecondaryUnit} onChange={(e) => setShowSecondaryUnit(e.target.value === 'true')}>
            <option value="true"> Yes </option>
            <option value="false"> No </option>
          </select>
        </Col>
      </Row>

    </>
  )



  const items = [
    {
      key: '1',
      label: 'Defualt Settings',
      children: <>{defualtForm}</>,
    },
    {
      key: '2',
      label: 'Cost Change',
      children: <>{costChangeForm}</>,
    },
    {
      key: '3',
      label: 'Selling Price',
      children: <>{sellingPriceForm}</>,
    },
    {
      key: '4',
      label: 'Quantity',
      children: <>{quantityForm}</>,
    },
  ];


  return (
    <div
      className="card" style={{ userSelect: 'none', height: '86vh', display: 'flex', flexDirection: 'row', overflowY: 'scroll' }}
    >

      <Collapse className="custom-collapse" style={{ width: '100%' }} defaultActiveKey={['1']}>
        {items.map(item => (
          <Panel header={item.label} key={item.key} style={{ backgroundColor: '#d8e1e8' }}>
            {item.children}
          </Panel>
        ))}
      </Collapse>

      <Button type="primary" size="large" loading={loadingEffect} onClick={handleSubmit}

        style={{ marginLeft: '10px', backgroundColor: '#1d7790' }}>Save</Button>



    </div>
  );
}

export default PiSetting;
