import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { DataContext } from '../../../init/getData';

import Select from 'react-select';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';


const ActionsPermission = (props) => {

  const { api, userData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const { showModal, toggleModal, refreshTable, tittle, idRow } = props;

  const { wareHouseData } = useContext(DataContext);


  const [editOrderAction, setEditOrderAction] = useState(false);

  // Sales
  const [useCreditAction, setUseCreditAction] = useState(false);
  const [useOrderAction, setUseOrderAction] = useState(false);
  const [allowedDiscount, setAllowedDiscount] = useState(false);
  const [wholeSale, setWholeSale] = useState(false);

  useEffect(() => {
    if(idRow){
      setEditOrderAction(idRow.editOrderAction)
      setUseCreditAction(idRow.useCreditAction)
      setUseOrderAction(idRow.useOrderAction)
      setAllowedDiscount(idRow.allowedDiscount)
      setWholeSale(idRow.wholeSale)

    }
  }, [idRow]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    let data= {
      id: idRow.id,
      editOrderAction: editOrderAction,
      useCreditAction: useCreditAction,
      allowedDiscount: allowedDiscount,
      wholeSale: wholeSale,
      useOrderAction: useOrderAction,
    }
      
    let res = await api.post(`/role/editActions`, JSON.stringify(data))
    if(res.data.status){
      toast.success(res.data.message);
      refreshTable();
      toggleModal();
    }
    else{
      toast.error(res.data.message);
    }
 
  }





  return (
      <Modal show={showModal} backdrop="static" size='xl' onHide={toggleModal} >
      <Modal.Header style={{ background: "#2f2927" }}>
      <Modal.Title style={{ color: "white" }}>{idRow.name} Actions Permission</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form onSubmit={handleSubmit} id='Form'>

      <Form.Group as={Row} className="mb-2">

        <Form.Label column sm={2}>Edit Order</Form.Label>
        <Col sm={2}>
          <label class="switch">
          <input type="checkbox"
          onChange={() => setEditOrderAction(!editOrderAction)}
          checked = {editOrderAction}
          ></input>
          <span class="slider"></span>
          </label>
        </Col>


        <Form.Label column sm={2}>Use Credit</Form.Label>
        <Col sm={2}>
          <label class="switch">
          <input type="checkbox"
          onChange={() => setUseCreditAction(!useCreditAction)}
          checked = {useCreditAction}
          ></input>
          <span class="slider"></span>
          </label>
        </Col>
        
        

             {/* Cash Actions  */}
      <Form style={{width: '25%'}}>

      <h4 style={{textAlign: 'left'}}>Sales Tab</h4>
      <div style={{borderTop: '1px solid #cccccc' , margin: '10px 0', marginTop: '4%', marginBottom: '15%'}}></div>

      <Form.Group as={Row} className="mb-2">
      <Form.Label column sm={8}>wholeSale</Form.Label>
          <label class="switch">
          <input type="checkbox"
          onChange={() => setWholeSale(!wholeSale)}
          checked = {wholeSale}
          ></input>
          <span class="slider"></span>
          </label>
        </Form.Group>



        <Form.Group as={Row} className="mb-2">
      <Form.Label column sm={8}>use Order</Form.Label>
          <label class="switch">
          <input type="checkbox"
          onChange={() => setUseOrderAction(!useOrderAction)}
          checked = {useOrderAction}
          ></input>
          <span class="slider"></span>
          </label>
        </Form.Group>


        <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={7}>Allowed Discount</Form.Label>
        <Col sm={4}>
        <div>
        <Form.Control type="number"  step='any' 
        value= {allowedDiscount} onChange={(e) => setAllowedDiscount(e.target.value)}
        />
        </div>
        </Col>





      </Form.Group>

  
      </Form>

      </Form.Group>


     
      
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <input type="submit" className="btn btn-primary" value="Save Data"></input>
      </div>

      </Form>
      </Modal.Body>
      </Modal>
      )
}


export default ActionsPermission;