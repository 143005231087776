import React, { useContext, useEffect, useMemo, useState, memo, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';


import toast from 'react-hot-toast';

import { DataContext } from '../../init/getData';

import moment from 'moment';


function WarrantyTable(props) {

  
  const { api, userData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const { search, wareHouse } = props;

  const [showAddModal, setShowAddModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showSerialModal, setShowSerialModal] = useState(false);

  const [idRow, setIdRow] = useState([]);

  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const [searchState, setSearchState] = useState(false);


  const timeoutRef = useRef(null);

//-----------------------------------------------------------------



  const addModal = (e) => {
    if (e != undefined) {
      setIdRow(e);}
      setShowAddModal(!showAddModal);
  }

  const removeModal = (e) => {
    if (e != undefined) {
      setIdRow(e);}
      setShowRemoveModal(!showRemoveModal);
  }
  
  const serialModal = (e) => {
    if (e != undefined) {
      setIdRow(e);}
      setShowSerialModal(!showSerialModal);
  }


  function dateFormatter(e) {
    let formattedDate = '';
    if(e.value){
    const date = new Date(e.value);
    formattedDate = moment.utc(date).format('DD-MM-YY hh:mm A');
    }
    return formattedDate;
  }

 
  

  useEffect(() => {

  let newColumnDefs = [
    { field: 'id' , hide: true},
    { field: 'createdAt', valueFormatter: dateFormatter, flex: 0.8},
    { field: 'product.name', headerName: 'Name', sortable: false, flex: 0.8},
    { field: 'product.barcode', headerName: 'Barcode', sortable: false, flex: 0.8},
    { field: 'serial', flex: 0.8},
    { field: 'isSold', flex: 0.4},
    { field: 'soldAt',valueFormatter: dateFormatter, flex: 0.8 },
    { field: 'order.account.name', headerName: 'Customer', flex: 0.8},
    { field: 'order.id', headerName: 'Order #', flex: 0.5},
    { field: 'purchaseId', headerName: 'Purchase #', flex: 0.6},
  ];

  setColumnDefs(newColumnDefs);

  if('true' == 'true'){
 
  newColumnDefs.push(

    );
  }


  if (gridApi) {
    gridApi.api.setServerSideDatasource(datasource);
     }

  }, [search, wareHouse]);



  const defaultColDef = useMemo(() => ({
    flex:1,
    filter: false,
    sortable: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
  }), []);


  const onGridReady = (params) => {
    setGridApi(params);
    params.api.setServerSideDatasource(datasource);
  }

  const refreshTable = () => {
  gridApi.api.refreshServerSide();

  }

  var datasource = useMemo(() => {
    return {
      getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
         
      
      let data = {
          startRow: startRow,
          endRow: endRow,
          search: search,
          wareHouseId: wareHouse.value,
        };

        //Sorting
        if (sortModel.length) {
        const { colId, sort } = sortModel[0]
        data.sortField = colId;
        data.sortDirection = sort;
      }

            
        api.get(`/warranty/getall`, {params: data}).then(response => {
        params.successCallback(response.data.data, response.data.totalRecords);
        if(!response.data.status){
        toast.error('Failed to get Data')
        }
      })
    }
  }
}, [search, wareHouse]);
  
  
  return (
    <div>     
    
  
    
      
      <div className="ag-theme-alpine" style={{ width: '100%', height: '77vh' }} >
        <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowModelType={'serverSide'}
        onGridReady={onGridReady}
        serverSideInfiniteScroll={true}
        pagination={true}
        paginationPageSize={60}
        cacheBlockSize={60}
        />
      </div>

      
    </div>
  );
}

export default WarrantyTable;
