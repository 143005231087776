import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';
import Select from 'react-select';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { print } from "../../../../dynamic/print";

import AccountAdd from '../../../../accountTab/account/crud/accountAdd';
import AccountEdit from '../../../../accountTab/account/crud/accounEdit';
import { editOrder, saveOrder } from './saveOrder';

import { FcPlus } from "react-icons/fc";
import { BiEdit } from "react-icons/bi";

import { DataContext } from '../../../../init/getData';
import { numberFormatter } from '../../../../dynamic/numberFormatter';

const Cash = (props) => {

 

  const { api, userData, accountData} = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;
  const wareHouseSaleId = userData.role.wareHouseSaleId;


  let { 
     showModal, toggleModal,
     deliveryData, settingData,
     cart,  isPrint, finalDiscount, isTva,
      editOrderId, finalNetTotal,
     closePaymentDone, selectedTab, removeTab,
     tableId,
    } = props;

  const [addCustomerModal, setAddCustomerModal] = useState(false)
  const [editCustomerModal, setEditCustomerModal] = useState(false)
  const [delivery, setDelivery] = useState({value: null, label: 'none', cost: 0, price: 0});
  const [customer, setCustomer] = useState({value: null, label: 'none'});
  const [idRow, setIdRow] = useState('');

  const [loadingEffect, setLoadingEffect] = useState(false);
  

   const selectCustomer = (customer) => {
      setCustomer(customer);
   }

 
   const closeModal = () => {
    toggleModal();
    setDelivery({value: null, label: 'none', cost: 0, price: 0});
    setCustomer({value: null, label: 'none'});
 }


    // customer Options for dropDown
    const customerOptions = accountData
    .filter(item => item.type === 'Customer')
    .map(item => ({
      value: item.id,
      id: item.id,
      label: `${item.name}${item.phone ? ` [${item.phone}]` : ''}${item.phone2 ? ` [${item.phone2}]` : ''}`,
      name: item.name,
      phone: item.phone,
      phone2: item.phone2,
      address: item.address,
      address2: item.address2,
    }));



    // Delivery Options for dropDown
    const deliveryOptions = deliveryData.map(item => ({
      value: item.id,
      name: item.name,
      label: item.name + ' [ ' + (settingData.isRated ? item.price * settingData.rate + settingData.ratedCurrency : item.price + settingData.currency ) + ' ]',
      cost: item.cost,
      price: item.price,
    }));

    // Delivery Option
    deliveryOptions.push({
      value: null,
      label: 'none',
      cost: 0,
      price: 0,
    });


    // Customer Option
    customerOptions.push({
      value: null,
      label: 'none',
    });

    // set Added Customer
    const setAddedCustomer = (latestCustomer) => {
      setCustomer({
        value: latestCustomer.id,
        id: latestCustomer.id,
        name: latestCustomer.name,
        label: latestCustomer.name + ' [' +latestCustomer.phone+ ']' + ' [' +latestCustomer.phone2+ ']',
        phone: latestCustomer.phone,
        phone2: latestCustomer.phone2,
        address: latestCustomer.address,
        address2: latestCustomer.address2,
      })
    }

      // set Edited Customer
      const setEditedCustomer = (editedCustomer) => {
        setCustomer(editedCustomer)
      }


    // Place Order
  const placeOrder = async() => {

    setLoadingEffect(true);

    let total = finalNetTotal+parseFloat(delivery.price);
    saveOrder(api, tableId.id, [], settingData.rate, closePaymentDone, toggleModal, total)

    
    let data = {};
     

   
  data = {
    userId : userData.id,
    wareHouseId: userData.role.wareHouseSaleId,
    accountId: customer.value,
    cart: cart,
    total: total,
    rate: settingData.rate,
    tva: isTva ? settingData.tva :0,
    type: 'Order',
    isOut: false,
    isCredit: false,
    discount: finalDiscount,
    deliveryId: delivery.value,
    deliveryCost: delivery.cost,
    deliveryPrice: delivery.price,
  };
  


  let res = await api.post(`/order/place`, JSON.stringify(data));
  if(res.data.status){
    toast.success(res.data.message);
    closePaymentDone();
    closeModal();
    setLoadingEffect(false);   

    if(isPrint){
      let printData ={
          cart: cart,
          total: finalNetTotal,
          settingData: settingData,
          discount: finalDiscount,
          userName: userName,
          invoiceId: res.data.id,
          customer: customer,
          delivery: delivery,
      }   
       print(printData)
    }
  }else{
    toast.error('Failed, Contact Support');
    setLoadingEffect(false);
  }

}



  return (
      <Modal show={showModal} size="lg" backdrop="static" onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Cash Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form id='addForm'>

    
      <Row className="mb-3" style={{ marginTop: '2%' }}>

    {/* Customer  */}
      <Form.Group as={Col}>
          <Form.Label>Customer</Form.Label>
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <div style={{ width: '100%'}}>
            <Select
            options={customerOptions}
            isSearchable={true}
            placeholder="Select"
            value={customer}
            onChange={(customer) => selectCustomer(customer)}
            />
            </div>
            <FcPlus size={30} style={{ cursor: 'pointer', marginLeft: '10px'}} onClick={() => setAddCustomerModal(!addCustomerModal)} />
            <BiEdit size={28} style={{ cursor: 'pointer', marginLeft: '10px'}} onClick={() => setEditCustomerModal(!editCustomerModal)} />
            
            </div>
        </Form.Group>


  
 




        </Row>
      


      {customer.value && (
      <Row className="mb-3" style={{ marginTop: '2%' }}>

      <Form.Group as={Col}>
          <Form.Label>Address</Form.Label>
          <textarea className="form-control" value={customer.address} name="address" id="address" disabled></textarea>
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Address2</Form.Label>
          <textarea className="form-control" value={customer.address2} name="address2" id="address2" disabled></textarea>
        </Form.Group>

        </Row>

      )}


<Row className="mb-3" style={{ marginTop: '2%' }}>

        {/* delivery  */}
        <Form.Group as={Col}>
          <Form.Label>Select Delivery</Form.Label>
          <Select
            options={deliveryOptions}
            isSearchable={true}
            placeholder="Select"
            value={delivery}
            onChange={setDelivery}
            />
        </Form.Group>
</Row>



      {/* Total */}
      <Row className="mb-3" style={{ marginTop: '2%', justifyContent: 'center' }}>
        <Form.Group as={Col} style={{ display: 'flex', justifyContent: 'center' }}>
          {!settingData.isRated && 
          <p style={{ color: 'green', fontWeight: 'bold', fontSize: '25px', marginTop:'20px' }}>Total: {numberFormatter(finalNetTotal + parseFloat(delivery.price), 2)}  {settingData.currency}</p>
          }
          {settingData.isRated && 
          <>
          <p style={{ color: 'green', fontWeight: 'bold', fontSize: '25px', marginTop:'20px', textAlign: 'center' }}>Total: {numberFormatter(finalNetTotal + parseFloat(delivery.price), 2)}  {settingData.currency}  </p>
          <p style={{ color: 'green', fontWeight: 'bold', fontSize: '25px', marginTop:'20px' , textAlign: 'center'}}> &nbsp; || {numberFormatter(((finalNetTotal + parseFloat(delivery.price))*settingData.rate), 2)} {settingData.ratedCurrency }</p>
          </>
          }
        </Form.Group>
      </Row>
   
        
   <div className="modal-footer">
        <button type="button" style={{height:'80px' , width: '20%'}} className="btn btn-secondary" onClick={closeModal}>
          Close
        </button>


        <button type="button" style={{height:'80px' , width: '20%'}} className="btn btn-success btn-lg "
         onClick={() => placeOrder('Cash')}>
          Pay Cash
          {loadingEffect &&  <span className="spinner-border spinner-border-sm"  role="status"  aria-hidden="true"
          style={{marginLeft: '5px'}}></span> }
        </button>
      </div>

      

      </Form>
      </Modal.Body>

      <AccountAdd   showModal={addCustomerModal}  toggleModal={() => setAddCustomerModal(!addCustomerModal)}   type={'Customer'} setAddedCustomer={setAddedCustomer} />
      <AccountEdit  showModal={editCustomerModal} toggleModal={() => setEditCustomerModal(!editCustomerModal)} type={'Customer'} setEditedCustomer={setEditedCustomer} idRow={customer} />



      </Modal>
      )
}


export default Cash;