import React, { useContext, useEffect, useMemo, useState, memo, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import SimpleStockAdd from './crud/simple/simpleStockAdd';
import SimpleStockRemove from './crud/simple/simpleStockRemove';
import SimpleStockTransfer from './crud/simple/simpleStockTransfer';

import SerialStockAdd from './crud/serialized/serialStockAdd';
import SerialStockRemove from './crud/serialized/serialStockRemove';
import SerialStockTransfer from './crud/serialized/serialStockTransfer';

import { DataContext } from '../../init/getData';

import toast from 'react-hot-toast';

import { FcPlus, FcMultipleInputs  } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";

function StockTable(props) {

  const { api, userData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const { isActive, search, setStockCost, wareHouseId, categoryId } = props;

  const [showTransferSimpleModal, setTransferSimpleModal] = useState(false);
  const [showTransferSerialModal, setTransferSerialModal] = useState(false);

  const [showAddSimpleModal, setShowAddSimpleModal] = useState(false);
  const [showAddSerialModal, setShowAddSerialModal] = useState(false);
  const [showAddVarientModal, setShowAddVarientModal] = useState(false);


  const [showRemoveSimpleModal, setShowRemoveSimpleModal] = useState(false);
  const [showRemoveSerialModal, setShowRemoveSerialModal] = useState(false);
  const [showRemoveVarientModal, setShowRemoveVarientModal] = useState(false);

  const [showSerialListModal, setShowSerialListModal] = useState(false);

  const [idRow, setIdRow] = useState([]);

  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const [searchState, setSearchState] = useState(false);


  const timeoutRef = useRef(null);

//-----------------------------------------------------------------


const addModal = (e, type) => {
  if (e != undefined) {setIdRow(e)}
  if(type == 'simple'){setShowAddSimpleModal(!showAddSimpleModal)};
  if(type == 'serialized'){setShowAddSerialModal(!showAddSerialModal)};
  if(type == 'varient'){showAddVarientModal(!setShowAddVarientModal)};
}

const removeModal = (e, type) => {
  if (e != undefined) {setIdRow(e)}
  if(type == 'simple'){setShowRemoveSimpleModal(!showRemoveSimpleModal)};
  if(type == 'serialized'){setShowRemoveSerialModal(!showRemoveSerialModal)};
  if(type == 'varient'){setShowRemoveVarientModal(!showRemoveVarientModal)};
}


const transferModal = (e, type) => {
  if (e != undefined) {setIdRow(e)}
  if(type == 'simple'){setTransferSimpleModal(!showTransferSimpleModal)};
  if(type == 'serialized'){setTransferSerialModal(!showTransferSerialModal)};
}

  useEffect(() => {

  let newColumnDefs = [
    { field: 'id' , hide: true},
    { field: 'barcode'},
    { field: 'name'},
    { field: 'totalStock', headerName: 'Stock'},
    { field: 'cost'},
    { field: 'price'},
    { field: 'category.name', headerName: 'Category', sortable: false},
    { field: 'type'},
  ];

  setColumnDefs(newColumnDefs);

  if(isActive == 'true'){

  newColumnDefs.push(

    {field: 'tansfer', headerName: 'TFR', flex: 0.6, sortable: false, cellRenderer: ({ data }) => {
      if (data.isStock) {
      return <FcMultipleInputs size={20} style={{cursor: 'pointer'}} onClick={() => transferModal(data, data.type)} /> 
      }}},

  {field: 'add', headerName: 'Add', flex: 0.6, sortable: false, cellRenderer: ({ data }) => {
    if (data.isStock) {
    return <FcPlus size={20} style={{cursor: 'pointer'}} onClick={() => addModal(data, data.type)} /> 
    }}},

  {field: 'remove', headerName: 'Rem', flex: 0.6, sortable: false, cellRenderer: ({ data }) => {
    if (data.isStock) {
      return <HiMinusCircle size={22} style={{cursor: 'pointer'}} onClick={() => removeModal(data, data.type)} /> 
    }}},
);
}


  if (gridApi) {
    gridApi.api.setServerSideDatasource(datasource);
     }

  }, [isActive, wareHouseId, search, categoryId]);



  const defaultColDef = useMemo(() => ({
    flex:1,
    filter: false,
    sortable: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
  }), []);


  const onGridReady = (params) => {
    setGridApi(params);
    params.api.setServerSideDatasource(datasource);
  }

  const refreshTable = () => {
  gridApi.api.refreshServerSide();

  }

  var datasource = useMemo(() => {
    return {
      getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
         
      
      let data = {
          startRow: startRow,
          endRow: endRow,
          search: search,
          isActive: isActive,
          isStock: true,
          wareHouseId: wareHouseId.value,
          categoryId: categoryId,
        };

        //Sorting
        if (sortModel.length) {
        const { colId, sort } = sortModel[0]
        data.sortField = colId;
        data.sortDirection = sort;
      }

            
        api.get(`/stock/getall`, {params: data}).then(response => {
        params.successCallback(response.data.data, response.data.totalRecords);
        setStockCost(response.data.stockCost);
        if(!response.data.status){
        toast.error('Failed to get Data')
        }
      })
    }
  }
}, [isActive, wareHouseId, search, categoryId]);
  





  
  return (
    <div>     
    
    {/* Simple Product*/}
    <SimpleStockAdd showModal={showAddSimpleModal} toggleModal={(e) => addModal({}, 'simple')} refreshTable={refreshTable} wareHouseId={wareHouseId} idRow={idRow} />
    <SimpleStockRemove showModal={showRemoveSimpleModal} toggleModal={(e) => removeModal(e,'simple')} refreshTable={refreshTable} wareHouseId={wareHouseId} idRow={idRow} />
    <SimpleStockTransfer showModal={showTransferSimpleModal} toggleModal={(e) => transferModal({} , 'simple')} refreshTable={refreshTable} wareHouseId={wareHouseId} idRow={idRow} />
    
    {/* Serialized Product*/}
    <SerialStockAdd showModal={showAddSerialModal} toggleModal={(e) => addModal({}, 'serialized')} refreshTable={refreshTable} wareHouseId={wareHouseId} idRow={idRow} />
    <SerialStockRemove showModal={showRemoveSerialModal} toggleModal={(e) => removeModal({},'serialized')} refreshTable={refreshTable} wareHouseId={wareHouseId} idRow={idRow} />
    <SerialStockTransfer showModal={showTransferSerialModal} toggleModal={(e) => transferModal({} , 'serialized')} refreshTable={refreshTable} wareHouseId={wareHouseId} idRow={idRow} />
    
 

      
      <div className="ag-theme-alpine" style={{ width: '100%', height: '77vh' }} >
        <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowModelType={'serverSide'}
        onGridReady={onGridReady}
        serverSideInfiniteScroll={true}
        pagination={true}
        paginationPageSize={60}
        cacheBlockSize={60}
        />
      </div>

      
    </div>
  );
}

export default StockTable;
