import React, {  useState, useEffect, createContext} from 'react';
import { NavLink } from 'react-router-dom';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { api } from "../dynamic/api";

import toast from 'react-hot-toast';
import Menu from '../menu/menu';

import { Bar, Pie  } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarController, BarElement, ArcElement, Tooltip, Legend } from 'chart.js';
Chart.register(CategoryScale, LinearScale, BarController, BarElement, ArcElement, Tooltip, Legend);

function Dashboard (){

  const data = {
    labels : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        label: 'Sales',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: 'rgba(75,192,192,1)',
      },
    ],
  };
  
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  


  return (
    <div className='card'>
    <span className='tittle' >Dashboard</span>

    <div style={{ display: 'flex' }}>
    <div style={{ width: '65%', height: '700px', paddingTop: '1%' ,paddingLeft: '2%'}}>
    <Bar data={data} options={options} />
    </div>


   <div style={{ width: '35%', height: '600px', paddingTop: '1%', paddingLeft: '5%' }}>
   <Pie data={data} options={options} />
   </div>
   </div>


  </div>
            
        );
    }

 
export default Dashboard;