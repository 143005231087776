import React, { useContext, useEffect, useMemo, useState, memo, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import moment from 'moment';
import toast from 'react-hot-toast';

import { DataContext } from '../../init/getData';
import { isOutFormatterStockActivity } from '../../dynamic/colorFormatter';

function StockActivityTable(props) {

  const { api, userData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const { isActive, search, fromDate, toDate, setTotalOut, setTotalIn, product, wareHouse, transactionType} = props;
  const {settingData} = useContext(DataContext);


  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const [searchState, setSearchState] = useState(false);


  const timeoutRef = useRef(null);

//-----------------------------------------------------------------

function dateFormatter(e) {
  const date = new Date(e.value);
  const formattedDate = moment.utc(date).format('DD-MM-YY hh:mm A');
  return formattedDate;
}

function typeComlumn(e) {
   
  if(e.data.type == 'in'){
   return <><span className='type-green'>in</span></>
  }else if(e.data.type == 'out'){
   return <><span className='type-red'> out</span></>
  }else if(e.data.type == 'transfer'){
    return <><span className='type-yellow'> transfer</span></>
   }

}


  useEffect(() => {

  let newColumnDefs = [
    { field: 'createdAt', valueFormatter: dateFormatter , flex: 0.7},
    { field: 'product.barcode', headerName: 'BarCode', flex: 0.6},
    { field: 'product.name', headerName: 'Product', sortable: false , flex: 0.7},
    { field: 'quantity', headerName: 'Qty', flex: 0.3},
    { field: 'cost', flex: 0.3},
    { field: 'averageCost', headerName: 'Avg', flex: 0.3},
    { field: 'transaction.type', headerName: 'Transaction', flex: 0.72, cellRenderer: isOutFormatterStockActivity,},
    { field: 'transaction.details', flex: 1.3},
    { field: 'transaction.id', headerName: 'T-ID', flex: 0.35},
    { field: 'transaction.created.name', headerName: 'User', sortable: false , flex: 0.35},
  ];

  setColumnDefs(newColumnDefs);


  if (gridApi) {
    gridApi.api.setServerSideDatasource(datasource);
     }

  }, [isActive, fromDate, toDate, product, wareHouse, search, transactionType]);



  const defaultColDef = useMemo(() => ({
    flex:1,
    filter: false,
    sortable: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
  }), []);


  const onGridReady = (params) => {
    setGridApi(params);
    params.api.setServerSideDatasource(datasource);
  }

  const refreshTable = () => {
  gridApi.api.refreshServerSide();

  }

  var datasource = useMemo(() => {
    return {
      getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
         
      
      let data = {
          startRow: startRow,
          endRow: endRow,
          search: search,
          isActive: isActive,
          fromDate: fromDate,
          toDate: toDate,
          wareHouseId: wareHouse.value,
          productId: product.value,
          transactionType: transactionType,
        };

        //Sorting
        if (sortModel.length) {
        const { colId, sort } = sortModel[0]
        data.sortField = colId;
        data.sortDirection = sort;
      }

            
        api.get(`/stockActivity/getall`, {params: data}).then(response => {
        params.successCallback(response.data.data, response.data.totalRecords);
        setTotalIn(response.data.totalIn);
        setTotalOut(response.data.totalOut);
        if(!response.data.status){
        toast.error('Failed to get Data')
        }
      })
    }
  }
}, [isActive, fromDate, toDate, product, wareHouse, search, transactionType]);
  




  
  return (
    <div>     

    
      
      <div className="ag-theme-alpine" style={{ width: '100%', height: '70vh' }} >
        <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowModelType={'serverSide'}
        onGridReady={onGridReady}
        serverSideInfiniteScroll={true}
        pagination={true}
        paginationPageSize={60}
        cacheBlockSize={60}
        />
      </div>

      
    </div>
  );
}

export default StockActivityTable;
