import React, {  useEffect, useMemo, useState, useRef, useContext } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';


import moment from 'moment';

import toast from 'react-hot-toast';


import { DataContext } from '../../init/getData';
import { numberFormatter } from '../../dynamic/numberFormatter';
import { dateFormatter, dateViewerFormatter } from '../../dynamic/dateFormatter';
import { isOutFormatterTransaction } from '../../dynamic/colorFormatter';




function ProductSalesTable(props) {

  const { fromDate, toDate, setTotal, search, setTotalRecords, setTotalDiscounts,
     setTotalRated, categoryId, userId, printProductSalesReport, setTotalItems,
     setTotalProfit,
     } = props;

  const { api, userData } = useContext(DataContext);


  const [showCartModal, setShowCartModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);

  const [idRow, setIdRow] = useState([]);

  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const [searchState, setSearchState] = useState(false);


  const timeoutRef = useRef(null);

//-----------------------------------------------------------------

  const cartModal = () => {
   setShowCartModal(!showCartModal)
  }

  const editModal = (e) => {
    if (e != undefined) {
      setIdRow(e);
    }
    setShowEditModal(prevshowEditModal => !prevshowEditModal);
  }


  

  useEffect(() => {

  let newColumnDefs = [
    { field: 'productId', headerName: 'Id',  flex: 0.5},
    { field: 'createdAt', valueFormatter: dateViewerFormatter},
    { field: 'product.barcode', },
    { field: 'name', },
    { field: 'quantity',  flex: 0.7},
    { field: 'order.isOut', headerName: 'Type' , cellRenderer: isOutFormatterTransaction},
    { field: 'totalPrice', valueFormatter: (e) => numberFormatter(e.value, 3) },
    { field: 'cost', },
    { field: 'profit', },
  ];


  setColumnDefs(newColumnDefs);




  if (gridApi) {
    gridApi.api.setServerSideDatasource(datasource);
     }

  }, [ fromDate, toDate, categoryId, userId, search]);


  const defaultColDef = useMemo(() => ({
    flex:1,
    filter: false,
    sortable: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    onCellDoubleClicked: handleDoubleClick,
  }), []);

  function handleDoubleClick(params) {
    setIdRow(params.node.data);
    cartModal();
  }




  const onGridReady = (params) => {
    setGridApi(params);
    params.api.setServerSideDatasource(datasource);
  }

  const refreshTable = () => {
  gridApi.api.refreshServerSide();

  }

  var datasource = useMemo(() => {
    return {
      getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
         
      
      let data = {
          startRow: startRow,
          endRow: endRow,
          search: search,
          isEnded: false,
          fromDate: fromDate,
          toDate: toDate,
          categoryId: categoryId,
          userId: userId,
        };

        //Sorting
        if (sortModel.length) {
        const { colId, sort } = sortModel[0]
        data.sortField = colId;
        data.sortDirection = sort;
      }

            
        api.get(`/productSales/getall`, {params: data}).then(response => {
        params.successCallback(response.data.data, response.data.totalRecords);
        printProductSalesReport(response.data.data)
        setTotalDiscounts(response.data.totalDiscounts)
        setTotal(response.data.total)
        setTotalRated(response.data.totalRated)
        setTotalRecords(response.data.totalRecords)
        setTotalItems(response.data.totalItems)
        setTotalProfit(response.data.totalProfit)
        if(!response.data.status){
        toast.error('Failed to get Data')
        }
      })
    }
  }
}, [ fromDate, toDate, categoryId, userId, search]);
  


  
  return (
    <div style={{userSelect: 'none'}}>  
      
      <div className="ag-theme-alpine" style={{ width: '100%', height: '70vh' }} >
        <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowModelType={'serverSide'}
        onGridReady={onGridReady}
        serverSideInfiniteScroll={true}
        pagination={true}
        paginationPageSize={99999999}
        cacheBlockSize={99999999}
        />
      </div>

      
    </div>
  );
}

export default ProductSalesTable;
