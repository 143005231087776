import React, { useContext, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

import { DataContext } from '../../init/getData';

import { Button } from 'antd';
import Form from 'react-bootstrap/Form';

const AddModal = (props) => {

  const { api, userData, settingData,  } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  
  const { showModal, toggleModal, table, title, refreshTable, formView, size, resetForm, footer, data } = props;
  const [loadingEffect, setLoadingEffect] = useState(false);
  const [validated, setValidated] = useState(false);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = document.getElementById("From");
    
    if (form.checkValidity() === false) {
      return;
    }
    
    setLoadingEffect(true);
    try {
      let res = await api.post(`${table}/add`, data);
      
      if (res.data.status) {
        toast.success(res.data.message);
        form.reset();
        if (resetForm) {
          resetForm();
        }
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Failed Contact Support");
    } finally {
      setLoadingEffect(false);
      setValidated(false);
    }
  }
  

  const closeModal = () => {
    toggleModal();
    if(refreshTable){refreshTable()};
    if(resetForm){resetForm()};
    setValidated(false);
  }


  return (
    <Modal show={showModal} backdrop="static" className={size === 'xxl' ? 'custom-modal-width' : ''} keyboard={false} size={size}  onHide={toggleModal}
     onKeyDown={(e) => {if (e.key === 'Enter'){e.preventDefault(); }  }} >
  <Modal.Header style={{ background: "#39aaa5" }} >
    <Modal.Title className='tittle-form' style={{ color: "white" }}>Add {title}</Modal.Title>
  </Modal.Header>
      <Modal.Body>

      <Form validated={validated} id='From'  onSubmit={handleSubmit}>

        {formView}
        <div className="modal-footer" style={{ justifyContent: 'space-between' }}>
        <div>
        {footer}
        </div>
        <div>
          <Button type="primary" size="large" onClick={closeModal} danger>Close</Button>
          <Button type="primary" size="large" loading={loadingEffect} htmlType="submit"
            onClick={ () => setValidated(true) }
           style={{marginLeft: '10px', backgroundColor: '#1d7790'}}>Save</Button>
        </div>
      </div>
    
      </Form>
    </Modal.Body>
      </Modal>
      )
}


export default AddModal;