import toast, {Toaster} from 'react-hot-toast';



  // Search Serial
  const handleSerial = (event, salableData, SerialSearch, setSerialSearch, handleAddToCart, returnMode) => {

 
    let productFound = null;
   
    if (event.key === 'Enter') {
          
      productFound = salableData.find(product =>
        product.stocks && product.stocks.length > 0 &&
        product.stocks.some(stock =>
          stock.serials && stock.serials.length > 0 &&
          stock.serials.some(serialObj => serialObj.serial === SerialSearch)
        )
      );

      if(productFound){

        const isSerialSold = productFound.stocks.some(stock => 
          stock.serials.some(s => s.serial === SerialSearch && s.isSold === true)
        );

        if(!returnMode && isSerialSold ){
          toast.error('This Serial is already Sold')
          setSerialSearch(''); 
          return;
        }

        if(returnMode && !isSerialSold ){
          toast.error('This Serial is already Sold')
          setSerialSearch(''); 
          return;
        }


        handleAddToCart(productFound, 1, SerialSearch)
      }else{
        toast.error('This Serial Doesnt Exist')
      }
      setSerialSearch(''); 
    }
    
  };

export {
  handleSerial,
};