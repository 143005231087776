import React, {  useState, useEffect, useContext} from 'react';
import { Route, Routes, useNavigate, NavLink} from 'react-router-dom';


import StockHeader from './stockControl/stockHeader';
import Product from './product/product';
import Category from './category/category';


import { DataContext } from '../init/getData';

import { FcPackage, FcOrgUnit, FcSettings, FcApproval, FcSurvey, FcHome } from "react-icons/fc";

function ProductTab(props) {

  const navigate = useNavigate();
  const { api, userData, settingData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role;

  const [activeTab, setActiveTab] = useState('product');

  useEffect(() => {

    navigate('/productTab/product');

  }, []);


  return (
    <div className="card" style={{paddingTop : '0px', userSelect: 'none'}}>
      
      <div>

      <NavLink to="product" onClick={() => setActiveTab('product')}>
          <div  className={`${activeTab === 'product' ? 'tab-one active-link' : 'tab-one'}`}>
          <FcPackage size={33}/>
            <span className="tab-text">Products</span>
          </div>
        </NavLink>

        
        <NavLink to="categories" onClick={() => setActiveTab('categories')}>
          <div  className={`${activeTab === 'categories' ? 'tab-two active-link' : 'tab-two'}`}>
          <FcOrgUnit size={35}/>
            <span className="tab-text">Categories</span>
          </div>
        </NavLink>
        
        {userRole.stockControlPage &&
        <NavLink to="stockControl" onClick={() => setActiveTab('stockControl')}>
          <div  className={`${activeTab === 'stockControl' ? 'tab-three active-link' : 'tab-three'}`}>
          <FcSettings size={35}/>
            <span className="tab-text">Stock Control</span>
          </div>
        </NavLink>
        }



      
      </div>

 
      <Routes>

        <Route path='product' element= {<Product/>}/>
        <Route path='categories' element= {<Category/>}/>
        <Route path='stockControl' element= {<StockHeader/>}/>
        
      </Routes>

    </div>
  );
}

export default ProductTab;
