import React, { useState, useEffect, useContext } from 'react';


function CustomButton(props) {

  const { method, tittle, color } = props;


  return (
    <div>
      <button onClick={method} type="button" className="btn btn-add"  >
        {tittle}
      </button>

    </div>
  )
}

export default CustomButton;
