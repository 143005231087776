import React, { useState, useEffect, useContext  } from 'react';
import Select from 'react-select';
import WarrantyTable from './warrantyTable';

import { DataContext } from '../../init/getData';


function WarrentyHeader(props) {
  
 
  const { api, userData, settingData, wareHouseData,  salableData } = useContext(DataContext);

  const [search, setSearch] = useState('');

  const [wareHouse, setWareHouse] = useState({ value: null, label: 'All WareHouse' });

      // warehouse options
      const wareHouseOptions = wareHouseData.map(item => ({
        value: item.id,
        label: item.name,
      }));

      wareHouseOptions.push({
        value: null, label: 'All WareHouse'
      })

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      setSearch(e.target.value)
    }
  };
  
  const handleSearchChange = (e) => {
    if(e.target.value == ''){
      setSearch(e.target.value)
    }
  };


  return (
    <div className='card' style={{userSelect: 'none'}}>

    
        <div>
        
        <input type="search" className="form-control search" placeholder="Search" 
        onKeyDown={(e) => handleSearch(e)}
        onChange={(e) => handleSearchChange(e)}>
        </input>

        <Select
           className='dropDownMedium'
            options={wareHouseOptions}
            value={wareHouse}
            onChange={setWareHouse}
            isSearchable={true}
            placeholder="Select"
            />


      </div>
  
      <WarrantyTable
       search={search}
       wareHouse={wareHouse}
       />
      

    </div>
  )
}

export default WarrentyHeader;
