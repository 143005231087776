import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { DataContext } from '../../../init/getData';

import Select from 'react-select';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';


const AccountAdd = (props) => {

  const { api, userData, refreshAccount } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const { showModal, toggleModal, refreshTable, type, setAddedCustomer } = props;
  const [loadingEffect, setLoadingEffect] = useState(false);
  
  const handleSubmit = async (e) => {
    setLoadingEffect(true);
    e.preventDefault();
    const form = document.getElementById("Form");
      
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);

    data['createdBy'] = userId;  
    data['type'] = type; 
    
    let res = await api.post(`/account/add`, JSON.stringify(data))
    if(res.data.status){
      toast.success(res.data.message);
      refreshAccount();
      toggleModal();
      form.reset();

      if(setAddedCustomer){
       setAddedCustomer(res.data.latestCustomer)
      }
    }else{
      toast.error(res.data.message);
    }
    
    
    if(refreshTable){
      refreshTable();
    }

    setLoadingEffect(false);
  }


  return (
      <Modal show={showModal} backdrop="static" size="lg" onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Add {type}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form onSubmit={handleSubmit} id='Form'>


      <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
            <Form.Label>Name</Form.Label>
            <input type="text"  name="name" id="name" className="form-control"></input>
          </Form.Group>

        </Row>


      <Row className="mb-3" style={{ marginTop: '2%' }}>

      <Form.Group as={Col}>
          <Form.Label>Phone</Form.Label>
          <input type="text"  name="phone" id="phone" className="form-control"></input>
        </Form.Group>


        <Form.Group as={Col}>
          <Form.Label>Phone2</Form.Label>
          <input type="text"  name="phone2" id="phone2" className="form-control"></input>
        </Form.Group>

      </Row>



      <Row className="mb-3" style={{ marginTop: '2%' }}>

<Form.Group as={Col}>
    <Form.Label>Address</Form.Label>
    <textarea className="form-control"  name="address" id="address"></textarea>
  </Form.Group>


  <Form.Group as={Col}>
    <Form.Label>Address2</Form.Label>
    <textarea className="form-control"  name="address2" id="address2"></textarea>
  </Form.Group>

</Row>
        
  
<Row className="mb-3" style={{ marginTop: '2%' }}>
<Form.Group as={Col}>
    <Form.Label>Details</Form.Label>
    <textarea className="form-control" name="details" id="details"></textarea>
  </Form.Group>

</Row>

      
      
<div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        
        <button className="btn btn-primary" onClick={handleSubmit}>Save Data
        {loadingEffect && 
        <span className="spinner-border spinner-border-sm" style={{ marginLeft: '10px'}} role="status" aria-hidden="true"></span>
          }
        </button>

      </div>

      </Form>
      </Modal.Body>
      </Modal>
      )
}


export default AccountAdd;