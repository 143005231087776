import React, { useState, useEffect, useContext } from 'react';


import SimpleTable from '../../components/tables/simpleTable';

import TrashModal from '../../components/modals/trashModal';
import RecoverModal from '../../components/modals/recoverModal';
import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';
import DeleteModal from '../../components/modals/deleteModal';

import SearchFilter from '../../components/filters/search';
import CustomButton from '../../components/buttons/customButton';
import IsActiveFilter from '../../components/filters/isActive';
import AddButton from '../../components/buttons/addButton';
import DataFilter from '../../components/filters/dataFilter';
import DatePicker from '../../components/filters/datePicker';

import { FaPlus } from "react-icons/fa";

import Loader from '../../components/loader/loader';
import { numberFormatter, totalFormatter } from '../../components/formatters/numberFormatter';
import { dateFormatter } from '../../dynamic/dateFormatter';
import { clientOrderColumnDefs } from '../../components/tables/columnDefs';
import { dateTableFormatter } from '../../dynamic/dateFormatter';
import { typeFormatter, paymentTypeFormatter } from '../../components/formatters/defFormatter';

import toast from 'react-hot-toast';

import { Space, Input, Tag, Button, Tabs } from 'antd';


import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcViewDetails, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { IoBarcodeOutline } from "react-icons/io5";
import { FcPlus } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";



import { DataContext } from '../../init/getData';




function AllTransactions(props) {

  let { } = props;


  const { userData, settingData, serverApi, api } = useContext(DataContext);
  const userRole = userData.role;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const createdBy = parseInt(userData.id);


  const today = new Date().toISOString().split('T')[0];

  const [idRow, setIdRow] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState({ search: '', isActive: 'true', fromDate: today, toDate: today, isOrder: false });

  const [showAddModal, setShowAddModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);


  const [name, setName] = useState('');
  const [category, setCategory] = useState([]);

  const table = 'transaction';
  const title = 'Transaction';


  const addModal = () => {
    setIdRow({});
    resetForm();
    setShowAddModal(!showAddModal);
  }

  const resetForm = () => {
    setCategory({ createdBy: createdBy })
  }

  const refreshTableFunction = () => {
    setRefreshTable(!refreshTable)
  }

  const recoverModal = (e) => {
    if (!showRecoverModal) {
      setIdRow(e);
    }
    setShowRecoverModal(!showRecoverModal);
  }

  const trashModal = (e) => {
    if (!showTrashModal) {
      setIdRow(e);
    }
    setShowTrashModal(!showTrashModal);
  }

  const deleteModal = (e) => {
    if (!showDeleteModal) {
      setIdRow(e);
    }
    setShowDeleteModal(!showDeleteModal);
  }

  const editModal = (e) => {
    if (!showEditModal) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }

  // idRow Change
  useEffect(() => {
    setCategory(
      {
        name: idRow?.name || '',
        createdBy: createdBy,
      }
    )
  }, [idRow]);


  // Generic handler for input changes
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (value === 'true' || value === 'false') {
      value = JSON.parse(value)
    }
    setCategory((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  //Excel Data
  const getExcelData =  (data) => {

    const transformedData = [];
    data.forEach(item => {
      
      const newItem = {
        id: item.id,
        createdAt: item.createdAt,
        account: item.account?.name,
        delivery: item.delivery?.name,
        total: item.total,
        discount: item.discount,
        type: item.type,
        isCredit: item.isCredit,
        details: item.details,
      };
  
      transformedData.push(newItem);
    });
  
    return transformedData;
  };

  let newColumnDefs = [
    { title: 'ID', dataIndex: 'id', width: '6%' },
    { title: 'CreatedAt', dataIndex: 'createdAt', render: dateTableFormatter },
    { title: 'Account', dataIndex: 'account', render: (account) => (account?.name) },
    { title: 'Delivery', dataIndex: 'delivery', render: (delivery) => (delivery?.name) },
    { title: 'Total', dataIndex: 'total', width: '8%' },
    { title: 'Discount %', dataIndex: 'discount', width: '8%' },
    { title: 'Type', dataIndex: 'type', render: typeFormatter },
    { title: 'Details', dataIndex: 'details' },
  ];


  useEffect(() => {

    setColumnDefs(newColumnDefs);

  }, []);


  const formView = (
    <div style={{ userSelect: 'none' }}>


      <Row className="mb-3">
        {/* Name  */}
        <Form.Group as={Col}>
          <Form.Label className='formLabel'>Name<span style={{ color: 'red' }}> *</span></Form.Label>
          <Form.Control type="text" name='name'
            value={category.name}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Row>

    </div >);




  const modalFooter = (
    <div>
    </div>
  );

  const tableFooter = (

    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
      <div>
        <span className='table-footer footer-one'>Balance: {numberFormatter(data?.summary?.totalCash + data?.summary?.totalSalesCash, 2)} {settingData.currency}</span>
      </div>
    </div>

  );


  return (
    <div className='card'>

      {isLoading &&
        <Loader />
      }


      {!isLoading &&
        <>
          <div>

            <SearchFilter setFilter={setFilter} />
            <DatePicker setFilter={setFilter} filter={filter} />

          </div>



          <SimpleTable
            filter={filter}
            showAddModal={showAddModal}
            addModal={addModal}
            table={table}
            title={title}
            columnDefs={columnDefs}
            idRow={idRow}
            setIdRow={setIdRow}
            refreshTable={refreshTable}
            data={data}
            setData={setData}
            tableFooter={tableFooter}
            api={api}
            getExcelData={getExcelData}
          />

          <TrashModal
            showModal={showTrashModal}
            toggleModal={trashModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <DeleteModal
            showModal={showDeleteModal}
            toggleModal={deleteModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <RecoverModal
            showModal={showRecoverModal}
            toggleModal={recoverModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <AddModal
            showModal={showAddModal}
            toggleModal={addModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'md'}
            resetForm={resetForm}
            footer={modalFooter}
            api={api}
            data={{ category }}
          />

          <EditModal
            showModal={showEditModal}
            toggleModal={editModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'md'}
            footer={modalFooter}
            api={api}
            data={{ id: idRow?.id, category }}
          />


        </>
      }

    </div>
  )
}

export default AllTransactions;
