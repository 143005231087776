import React, { useState, useEffect, useContext } from 'react';

import ProductSalesTable from './productSalesTable';
import ProductSalesReport from './productSalesReport';


import toast from 'react-hot-toast';
import { DataContext } from '../../init/getData';


import { numberFormatter } from '../../dynamic/numberFormatter';
import { defaultDateGetter, dateHourFormatter } from '../../dynamic/dateFormatter';
import { printProductSalesReport } from '../../dynamic/print';

import Select from 'react-select';
import { FcMoneyTransfer, } from "react-icons/fc";

function ProductSalesHeader(props) {

  const { type } = props;

  const [totalProfit, setTotalProfit] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalRated, setTotalRated] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const [totalDiscounts, setTotalDiscounts] = useState(0);


  const [showReportModal, setShowReportModal] = useState(false);

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [categoryId, setCategoryId] = useState({ value: null, label: 'All Categories' });
  const [userId, setUserId] = useState({ value: null, label: 'All Users' });

  const [search, setSearch] = useState('');
  const { userData, categoryData, settingData, allUserData } = useContext(DataContext);
  const closingHour = settingData.closingHour;



  useEffect(() => {
    const getDate = defaultDateGetter(settingData.defaultDate);
    const fromDate = getDate.fromDate;
    const toDate = getDate.toDate;
    document.getElementById('fromDate').value = fromDate;
    document.getElementById('toDate').value = toDate;

    setFromDate(dateHourFormatter(fromDate, 'from', closingHour));
    setToDate(dateHourFormatter(toDate, 'to', closingHour));
  }, []);


  // Users Options for dropDown
  const userOptions = allUserData.map(item => ({
    value: item.id,
    label: item.name
  }));

  userOptions.push({
    value: null,
    label: 'All Users',
  });


  // Category Options for dropDown
  const categoryOptions = categoryData.map(item => ({
    value: item.id,
    label: item.name
  }));


  categoryOptions.push({
    value: null,
    label: 'All Categories',
  });


  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      setSearch(e.target.value)
    }
  };

  const handleSearchChange = (e) => {
    if (e.target.value == '') {
      setSearch(e.target.value)
    }
  };



  return (
    <div className='card' style={{ userSelect: 'none' }}>



      <div>

        <input type="search" className="form-control search" placeholder="Search"
          onKeyDown={(e) => handleSearch(e)}
          onChange={(e) => handleSearchChange(e)}>
        </input>

        <div className="col-sm-10">
          <input type="date" name="fromDate" id="fromDate" className="form-control date"
            onChange={(e) => setFromDate(dateHourFormatter(e.target.value, 'from', closingHour))}   ></input>

          <input type="date" name="toDate" id="toDate" className="form-control date"
            onChange={(e) => setToDate(dateHourFormatter(e.target.value, 'to', closingHour))} ></input>
        </div>

        <Select
          className='dropDownCategory'
          options={userOptions}
          isSearchable={true}
          placeholder="Select"
          value={userId}
          onChange={setUserId}
        />

        <Select
          className='dropDownCategory'
          options={categoryOptions}
          isSearchable={true}
          placeholder="Select"
          value={categoryId}
          onChange={setCategoryId}
        />


        <button type="button" className="btn btn-add" onClick={() => setShowReportModal(!showReportModal)}> Print Report  </button>


      </div>



      <ProductSalesTable
        type={type}
        fromDate={fromDate}
        toDate={toDate}
        search={search}
        setTotalRecords={setTotalRecords}
        setTotalDiscounts={setTotalDiscounts}
        setTotal={setTotal}
        setTotalRated={setTotalRated}
        categoryId={categoryId.value}
        userId={userId.value}
        printProductSalesReport={printProductSalesReport}
        setTotalItems={setTotalItems}
        setTotalProfit={setTotalProfit}
      />

      {showReportModal && userData &&
        <ProductSalesReport
          fromDate={fromDate}
          toDate={toDate}
          showModal={showReportModal}
          toggleModal={() => setShowReportModal(!showReportModal)}
          categoryData={categoryData}
          user={userId}
          totalDiscounts={totalDiscounts}
        />
      }

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '3vh' }}>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', textAlign: 'center', width: 'auto', backgroundColor: '#395077', color: 'white', marginLeft: '20px', padding: '5px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px' }}>
            <a>Total Items: {numberFormatter(totalItems, 2)} pcs </a>
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', textAlign: 'center', width: 'auto', backgroundColor: '#28a745', color: 'white', marginLeft: '20px', padding: '5px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px' }}>
            <a>Sales: {numberFormatter(total, 2)} {settingData.currency} </a>
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', textAlign: 'center', width: 'auto', backgroundColor: '#ff4364', color: 'white', marginLeft: '20px', padding: '5px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px' }}>
            <a>Cost: {numberFormatter(total - totalProfit, 2)} {settingData.currency} </a>
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', textAlign: 'center', width: 'auto', backgroundColor: '#ff9902', color: 'white', marginLeft: '20px', padding: '5px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px' }}>
            <a>Total Discounts: {numberFormatter(totalDiscounts, 2)} {settingData.currency} </a>
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', textAlign: 'center', width: 'auto', backgroundColor: '#1d7790', color: 'white', marginLeft: '20px', padding: '5px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px' }}>
            <a>Total Profit: {numberFormatter(totalProfit, 2)} {settingData.currency} </a>
          </div>
        </div>

        {settingData.isRated &&
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', textAlign: 'center', width: 'auto', backgroundColor: '#2eb486', color: 'white', marginLeft: '20px', padding: '5px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px' }}>
              <a>Total Rated: {numberFormatter(totalRated, 2)} {settingData.ratedCurrency}</a>
            </div>
          </div>
        }

      </div>


    </div>
  )
}

export default ProductSalesHeader;
