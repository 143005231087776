import React, { useState, useEffect, useContext } from 'react';

import StockActivityTable from './stockActivityTable';
import { DataContext } from '../../init/getData';

import Select from 'react-select';
import { FcLeftUp2, FcMultipleInputs, FcUp } from "react-icons/fc";
import { defaultDateGetter, dateHourFormatter } from '../../dynamic/dateFormatter';
import {  Switch, Input, Tag, Spin } from 'antd';

function StockActivityHeader(props) {

  const { api, userData, settingData, refWareHouseData, wareHouseData, salableData, refSalableData } = useContext(DataContext);
  const closingHour = settingData.closingHour;
  const userRole = userData.role;

  const [search, setSearch] = useState('');

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const [totalIn, setTotalIn] = useState();
  const [totalOut, setTotalOut] = useState();

  const [transactionType, setTransactionType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [product, setProduct] = useState({ value: null, label: 'All Products' });


  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        await refSalableData();
        await refWareHouseData();
        setIsLoading(false)
      } catch (error) {
        console.error('Error Getting Data');
      }
    };
    fetchData();
  }, []);




  //Products options
  const productOptions = salableData.map(item => ({
    value: item.id,
    label: `${item.name}${item.barcode ? ` [${item.barcode}]` : ''}`,
  }))

  productOptions.push(
    { value: null, label: 'All Products' }
  )

  // warehouse options
  const wareHouseOptions = wareHouseData.map(item => ({
    value: item.id,
    label: item.name,
  }));

  const defaultWareHouse = wareHouseOptions.find(option => option.value === parseInt(userRole.defaultWareHouseId));

  const [wareHouse, setWareHouse] = useState(defaultWareHouse || { value: null, label: 'All WareHouse' });


  useEffect(() => {
    const getDate = defaultDateGetter(settingData.defaultDate);
    const fromDate = getDate.fromDate;
    const toDate = getDate.toDate;
    document.getElementById('fromDate').value = fromDate;
    document.getElementById('toDate').value = toDate;

    setFromDate(dateHourFormatter(fromDate, 'from', closingHour));
    setToDate(dateHourFormatter(toDate, 'to', closingHour));
  }, []);



  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      setSearch(e.target.value)
    }
  };

  const handleSearchChange = (e) => {
    if (e.target.value == '') {
      setSearch(e.target.value)
    }
  };


  return (
    <div className='card' style={{ userSelect: 'none' }}>

      <Spin size="large" spinning={isLoading} fullscreen />
      <div>

        <input type="search" className="form-control search" placeholder="Search"
          onKeyDown={(e) => handleSearch(e)}
          onChange={(e) => handleSearchChange(e)}>
        </input>


        <div className="col-sm-10">
          <input type="date" name="fromDate" id="fromDate" className="form-control date"
            onChange={(e) => setFromDate(dateHourFormatter(e.target.value, 'from', closingHour))}   ></input>

          <input type="date" name="toDate" id="toDate" className="form-control date"
            onChange={(e) => setToDate(dateHourFormatter(e.target.value, 'to', closingHour))} ></input>
        </div>


        <Select
          className='dropDown'
          options={wareHouseOptions}
          value={wareHouse}
          onChange={setWareHouse}
          isSearchable={true}
          placeholder="Select"
        />


        <Select
          className='dropDownCategory'
          options={productOptions}
          value={product}
          onChange={setProduct}
          isSearchable={true}
          placeholder="Select"
        />


        <select
          className="form-control dropDownStockActivity"
          onChange={(e) => setTransactionType(e.target.value === 'null' ? null : e.target.value)}
        >
          <option value='null'>All Transactions</option>
          <option value='PurchaseInvoice'>PurchaseInvoice</option>
          <option value='Order'>Order</option>
        </select>



      </div>

      <StockActivityTable
        isActive={'true'}
        search={search}
        fromDate={fromDate}
        toDate={toDate}
        setTotalOut={setTotalOut}
        setTotalIn={setTotalIn}
        product={product}
        wareHouse={wareHouse}
        transactionType={transactionType}
      />


      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '3vh' }}>





        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', textAlign: 'center', width: 'auto', backgroundColor: '#30b255', color: 'white', marginLeft: '20px', padding: '5px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5px', marginRight: '5px' }}>
            <a>Total In: {totalIn}</a>
            <a></a>
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', textAlign: 'center', width: 'auto', backgroundColor: '#ff4364', color: 'white', marginLeft: '20px', padding: '5px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5px', marginRight: '5px' }}>
            <a>Total Out: {totalOut}</a>
            <a></a>
          </div>
        </div>



      </div>

    </div>
  )
}

export default StockActivityHeader;
