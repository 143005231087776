import React, { useEffect, useMemo, useState, useContext, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import UserAdd from './crud/userAdd';
import UserEdit from './crud/userEdit';
import UserTrash from './crud/userTrash';
import UserRecover from './crud/userRecover';

import toast from 'react-hot-toast';

import delete_png from '../../images/trash.png';

import { FiEdit } from "react-icons/fi";
import { FcUndo, FcSurvey   } from "react-icons/fc";

import { DataContext } from '../../init/getData';

function UserTable(props) {

  const { api, userData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const { isActive, showAddModal, addModal, search } = props;

  const {settingData, deliveryData} = useContext(DataContext);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);

  const [idRow, setIdRow] = useState([]);

  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const [searchState, setSearchState] = useState(false);
  const timeoutRef = useRef(null);

//-----------------------------------------------------------------




  const editModal = (e) => {
    if (e != undefined) {
      setIdRow(e);
    }
    setShowEditModal(prevshowEditModal => !prevshowEditModal);
  }

  const trashModal = (e) => {
    if (e != undefined) {
      setIdRow(e);}
      setShowTrashModal(prevshowTrashModal => !prevshowTrashModal);
  }

  const recoverModal = (e) => {
    if (e != undefined) {
      setIdRow(e);}
      setShowRecoverModal(!showRecoverModal);
  }

 

  
  

  useEffect(() => {



  let newColumnDefs = [
    { field: 'id', flex : 0.5},
    { field: 'name' },
    { field: 'email'},
    { field: 'password'},
    { field: 'role.name'},
  ];



  setColumnDefs(newColumnDefs);

  if(isActive == 'true'){
    console.log(isActive);
  newColumnDefs.push(
    {field: 'edit', headerName: 'Edt', flex: 0.6, sortable: false, cellRenderer: ({ data }) => (
      <FiEdit size={18} style={{cursor: 'pointer'}} onClick={() => editModal(data)} />
      )},
    
    {field: 'delete', headerName: 'Del', flex: 0.6, sortable: false, cellRenderer: ({ data }) => (
      <input type="image" src={delete_png} onClick={e => trashModal(data)} width={20} style={{ paddingTop: '10px'}} />
    )},
    );
  }else{
    newColumnDefs.push(      
      {field: 'recover', headerName: 'Rec', flex: 0.5, sortable: false, cellRenderer: ({ data }) => (
        <FcUndo size={25} style={{cursor: 'pointer'}} onClick={() => recoverModal(data)} />
      )},
      );

  }

  if (gridApi) {
    gridApi.api.setServerSideDatasource(datasource);
     }

  }, [isActive]);


  const defaultColDef = useMemo(() => ({
    flex:1,
    filter: false,
    sortable: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
  }), []);




  const onGridReady = (params) => {
    setGridApi(params);
    params.api.setServerSideDatasource(datasource);
  }

  const refreshTable = () => {
  gridApi.api.refreshServerSide();

  }

  var datasource = useMemo(() => {
    return {
      getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
         
      
      let data = {
          startRow: startRow,
          endRow: endRow,
          isActive: isActive,
        };

        //Sorting
        if (sortModel.length) {
        const { colId, sort } = sortModel[0]
        data.sortField = colId;
        data.sortDirection = sort;
      }

            
        api.get(`/user/getall`, {params: data}).then(response => {
        params.successCallback(response.data.data, response.data.totalRecords);
        if(!response.data.status){
        toast.error('Failed to get Data')
        }
      })
    }
  }
}, [isActive]);
  



  
  
  return (
    <div>     
    
    <UserAdd showModal={showAddModal}  toggleModal={addModal}tittle={'User'} refreshTable={refreshTable} deliveryData={deliveryData} />
    <UserEdit showModal={showEditModal} toggleModal={editModal} tittle={'User'} refreshTable={refreshTable}  idRow={idRow} deliveryData={deliveryData} />
    <UserTrash showModal={showTrashModal} toggleModal={trashModal} tittle={'User'} refreshTable={refreshTable} idRow={idRow} />
    <UserRecover showModal={showRecoverModal} toggleModal={recoverModal} tittle={'User'} refreshTable={refreshTable} idRow={idRow} />
   
      
      <div className="ag-theme-alpine" style={{ width: '100%', height: '75vh' }} >
        <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowModelType={'serverSide'}
        onGridReady={onGridReady}
        serverSideInfiniteScroll={true}
        pagination={true}
        paginationPageSize={10}
        cacheBlockSize={10}
        />
      </div>

      
    </div>
  );
}

export default UserTable;
