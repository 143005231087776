import React, { useState, useEffect, useContext  } from 'react';

import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { DataContext } from '../../init/getData';

import { numberFormatter } from '../../dynamic/numberFormatter';
import { datePrintReportFormatter } from '../../dynamic/dateFormatter';
import { printProductSalesReport } from '../../dynamic/print';

import { FcMoneyTransfer, } from "react-icons/fc";

import Select from 'react-select';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

function ProductSalesReport(props) {

  
  const { api, userData, settingData, customerData, deliveryData } = useContext(DataContext);
  
  const {showModal, toggleModal, idRow, fromDate, toDate, categoryData, user, totalDiscounts} = props;

  const [loadingEffect, setLoadingEffect] = useState(false);
  const [reportData, setReportData] = useState(false);


  
  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = {
          fromDate: fromDate,
          toDate: toDate,
        };
        const response = await api.get(`/productSales/getall`, { params: data });
        if (response) {
          let filteredData = response.data.data;
          if(user.value){
            filteredData = response.data.data.filter(data => data.transaction.createdBy === user.value);
          }
          setReportData(filteredData);
        }
        setLoadingEffect(false);
      } catch (error) {
        toast.error('' + error);}};

    if(showModal){
      setLoadingEffect(true);
    fetchData();
    }
  }, [showModal]);





  const print = async() => {

    const form = document.createElement("formid");

    let totalSales = 0;

    
    form.innerHTML = `
    <Form >
    
    <div style="display: flex; justify-content: space-between;">
      <h5>From Date: ${datePrintReportFormatter(fromDate)}</h5>
      <h5 style="text-align: right;">User: ${user.label}</h5>
    </div>
    
    <div style="display: flex; justify-content: space-between;">
      <h5>To Date: ${datePrintReportFormatter(toDate)}</h5>
    </div>
    
    ${reportData &&
      categoryData
        .map(
          (category, index) => {
            
            const categoryReports = reportData.filter(report => report.product.categoryId === category.id);
            const categoryTotal = categoryReports.reduce((total, report) => {
              const totalPrice = parseFloat(report.totalPrice);
              return report.transaction.isOut ? total - totalPrice : total + totalPrice;
            }, 0);

            if (categoryReports.length > 0) {
              totalSales += categoryTotal;
            
    
              return `
                <div key=${index} style="text-align: center;">
    
                  <Row className="mb-3">
    
                    <div>
                      <p style="font-size: 25px; margin-top: 10%;">${category.name}</p>
    
                      <table class="table table-bordered" style="width: 100%;  border-collapse: collapse; border: 1px solid black;">
    
                        <thead> 
                          <tr>
                            <th style="width: 30%; text-align: left; border: 1px solid black;">Item</th>
                            <th style="width: 10%; text-align: left; border: 1px solid black;">Type</th>
                            <th style="width: 10%; text-align: left; border: 1px solid black;">Qnty</th>
                            <th style="width: 10%; text-align: left; border: 1px solid black;">Total</th>
                          </tr>
                        </thead>
    
                        <tbody>
                          ${categoryReports
                            .map(
                              (report, index) => `
                                <tr key=${index}>
                                  <td style="width: 30%; text-align: left; border: 1px solid black;">${report.name}</td>
                                  <td style="width: 30%; text-align: left; border: 1px solid black;">${report.transaction.isOut? 'Return': 'Order'}</td>
                                  <td style="width: 10%; text-align: left; border: 1px solid black;">${report.quantity}</td>
                                  <td style="width: 10%; text-align: left; border: 1px solid black;">${numberFormatter(report.totalPrice, 2)}</td>
                                </tr>
                              `
                            ).join('')
                          }
    
                          <tr>
                            <td colspan="3" style="border: 1px solid black;">
                              <p style=" font-weight: bold; text-align: center;">
                                Total: ${numberFormatter(categoryTotal, 2)} ${settingData.currency}
                              </p>
                            </td>
                          </tr>
    
                        </tbody>
    
                      </table>
    
                    </div>
    
                  </Row>
    
                </div>
              `;
            } else {
              return ''; // If no reports, return an empty string
            }
          }
        )
        .join('')}
    
        ${totalSales > 0 && 
          `<div>
            <p style="font-weight: bold; text-align: center; margin-top: 10%; font-size: 30px;">
              Sales: ${numberFormatter(totalSales, 2)} ${settingData.currency}
            </p>
          </div>`
        }

        ${totalSales > 0 && 
          `<div>
            <p style="font-weight: bold; text-align: center; margin-top: 0; font-size: 30px;">
              Discounts: ${numberFormatter(totalDiscounts, 2)} ${settingData.currency}
            </p>
          </div>`
        }

        ${totalSales > 0 && 
          `<div>
            <p style="font-weight: bold; text-align: center; margin-top: 0; font-size: 30px;">
              Total: ${numberFormatter(totalSales-totalDiscounts, 2)} ${settingData.currency}
            </p>
          </div>`
        }


    </Form>
    `;

    const newWindow = window.open();
    newWindow.document.body.appendChild(form);
      await new Promise(resolve => setTimeout(resolve, 10));
      newWindow.print();
    

    newWindow.close();

  }
  



  
  return (
    <Modal show={showModal} size='lg' onHide={toggleModal}>
    <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Sales Report</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5>From Date: {datePrintReportFormatter(fromDate)} </h5>
          <h5 style={{ textAlign: 'right' }}>User: {user.label} </h5>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5>To Date: {datePrintReportFormatter(toDate)} </h5>
        </div>
        {reportData && categoryData.map((category, index) => {
          const categoryReports = reportData.filter(report => report.product.categoryId === category.id);
          if (categoryReports.length === 0) {
            return null; // Skip rendering this category if there are no reports
          }
          return (
            <div key={index}>
              <Row className="mb-3" style={{ marginTop: '2%' }}>
                <Form.Group as={Col} style={{ textAlign: 'center' }}>
                  <Form.Label style={{ fontSize: '25px' }}>{category.name}</Form.Label>
                  <table class="table table-bordered" style={{ marginBottom: '5%' }}>
                    <thead>
                      <tr>
                        <th style={{ width: '30%', textAlign: 'left' }}>Item</th>
                        <th style={{ width: '10%', textAlign: 'left' }}>Type</th>
                        <th style={{ width: '10%', textAlign: 'left' }}>Qnty</th>
                        <th style={{ width: '10%', textAlign: 'left' }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoryReports.map((report, index) => (
                        <tr key={index}>
                          <td style={{ width: '30%', textAlign: 'left' }}>{report.name}</td>
                          <td style={{ width: '30%', textAlign: 'left' }}> {report.transaction.isOut? 'Return': 'Order'}</td>
                          <td style={{ width: '30%', textAlign: 'left' }}> {report.quantity}</td>
                          <td style={{ width: '20%', textAlign: 'left' }}>{numberFormatter(report.totalPrice, 2)}</td>
                        </tr>
                      ))}
                   
                    </tbody>
                    <a style={{ color: 'green', fontWeight: 'bold', textAlign: 'right' }}>
                      Total: {numberFormatter(
                        categoryReports.reduce((total, report) => {
                          const totalPrice = parseFloat(report.totalPrice);
                          return report.transaction.isOut ? total - totalPrice : total + totalPrice;}, 0),2 )}
                      {settingData.currency}
                    </a>
                  </table>
                </Form.Group>
              </Row>
            </div>
          );
        })}

      
          
        {reportData && reportData.length > 0 && (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1%' }}>
          <h4>Sales:</h4>
          <h4 >
           {numberFormatter(reportData.reduce((total, report) => {
            const totalPrice = parseFloat(report.totalPrice);
            return report.transaction.isOut ? total - totalPrice : total + totalPrice;
          }, 0), 2)} {settingData.currency}
          </h4>
          </div>
          )}

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1%' }}>
          <h4>Discounts:</h4>
          <h4>{numberFormatter(totalDiscounts, 2)}{settingData.currency}</h4>
          </div>

          {reportData && reportData.length > 0 && (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1%' }}>
          <h4>Total:</h4>
          <h4 style={{  textAlign: 'right', color: 'green' }}>
            {numberFormatter(reportData.reduce((total, report) => {
            const totalPrice = parseFloat(report.totalPrice);
            return report.transaction.isOut ? total - totalPrice : total + totalPrice;
          }, 0) - totalDiscounts, 2)}{settingData.currency}</h4>
          </div>
          )}


     
  

      

      </Form>
    </Modal.Body>
    <Modal.Footer>
      <button type="button" className="btn btn-secondary" onClick={toggleModal}>Close</button>
      <button type="button" className="btn btn-primary" onClick={print}>Print</button>

      {loadingEffect && 
        <div style={{ position: 'absolute', top: '50%', left: '50%'}}>
      <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
        </div>
      }
      
    </Modal.Footer>
  </Modal>
  )
}

export default ProductSalesReport;
