import React, { useState, useEffect, useContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Switch } from 'antd';


import Select from 'react-select';

import { DataContext } from '../init/getData';

function GeneralSetting() {

  const { api, userData, refreshSetting, settingData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;


  const [rate, setRate] = useState([]);
  const [tva, setTva] = useState('');
  const [currency, setCurrency] = useState({ value: '', label: '' });
  const [ratedCurrency, setRatedCurrency] = useState({ value: '', label: '' });
  const [rateMultiply, setRateMultiply] = useState('');

  const [costChangerMethod, setCostChangerMethod] = useState('');


  const [useShipping, setUseShipping] = useState([]);
  const [shippingCost, setShippingCost] = useState([]);
  const [shippingRate, setShippingRate] = useState([]);

  const [isRated, setIsRated] = useState(false);
  const [defaultTva, setDefaultTva] = useState(false);
  const [language, setLanguage] = useState('');
  const [moneyExchange, setMoneyExchange] = useState(false);
  const [printDirect, setPrintDirect] = useState(false);
  const [showZeroStock, setShowZeroStock] = useState(false);

  const [closingHour, setClosingHour] = useState('');
  const [defaultDate, setdefaultDate] = useState('');

  const [lowInStock, setLowInStock] = useState('');

  useEffect(() => {
  }, [settingData]);

  useEffect(() => {
    if (settingData) {
      setLanguage(settingData.language);
      setRate(settingData.rate);
      setTva(settingData.tva);
      setDefaultTva(settingData.defaultTva)
      setClosingHour(settingData.closingHour);
      setdefaultDate(settingData.defaultDate);
      setLowInStock(settingData.lowInStock);
      setCostChangerMethod(settingData.costChangerMethod)
      setMoneyExchange(settingData.moneyExchange)
      setPrintDirect(settingData.printDirect)
      setShowZeroStock(settingData.showZeroStock)

      setIsRated(settingData.isRated)
      setRateMultiply(settingData.rateMultiply)

      setUseShipping(settingData.useShipping);
      setShippingCost(settingData.shippingCost);
      setShippingRate(settingData.shippingRate)

      setCurrency(currencyOptions.find(option => option.value === settingData.currency))
      setRatedCurrency(currencyOptions.find(option => option.value === settingData.ratedCurrency))
    }
  }, [settingData]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = {
      language: language,
      rate: rate,
      tva: tva,
      currency: currency.value,
      useShipping: useShipping,
      shippingCost: shippingCost,
      shippingRate: shippingRate,
      closingHour: closingHour,
      defaultDate: defaultDate,
      isRated: isRated,
      ratedCurrency: ratedCurrency.value,
      defaultTva: defaultTva,
      lowInStock: lowInStock,
      costChangerMethod: costChangerMethod,
      moneyExchange: moneyExchange,
      printDirect: printDirect,
      showZeroStock: showZeroStock,
      rateMultiply: rateMultiply,
    }

    let res = await api.post(`/setting/edit`, JSON.stringify(data))
    if (res.data.status) {
      toast.success(res.data.message);
      refreshSetting();
    }
    else {
      toast.error(res.data.message);
    }

  }



  const currencyOptions = [
    { value: '$', label: 'Dollar' },
    { value: 'LL', label: 'Lebanese Lira' },
    { value: '€', label: 'Euro' },
    { value: 'CFA', label: 'CFA franc' },
    { value: 'AED', label: 'United Arab Emirates Dirham' },
  ];


  return (
    <div className="card" style={{ userSelect: 'none', height: 'auto', display: 'flex', flexDirection: 'row' }}>


      <Form onSubmit={handleSubmit} style={{ width: '45%', margin: '0', marginTop: '30px' }} id='settingForm'>



        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3}>Closing Hour</Form.Label>
          <Col sm={9}>
            <div>
              <Form.Control value={closingHour} onChange={(e) => setClosingHour(e.target.value)} type="number" step='any' placeholder="hours after 12" style={{ width: '70%' }} />
            </div>
          </Col>
        </Form.Group>


        {/*  Default Date */}
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
          <Form.Label column sm={3}>
            Default Date
          </Form.Label>
          <Col sm={9}>
            <select className="form-control" style={{ width: '70%' }} value={defaultDate} onChange={(e) => setdefaultDate(e.target.value)}>
              <option value='daily'> Daily </option>
              <option value='monthly'> Monthly </option>
              <option value='yearly'> Yearly </option>
            </select>
          </Col>
        </Form.Group>


        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3}>Low In Stock</Form.Label>
          <Col sm={9}>
            <div>
              <Form.Control value={lowInStock} onChange={(e) => setLowInStock(e.target.value)} type="number" step='any' placeholder="notification#" style={{ width: '70%' }} />
            </div>
          </Col>
        </Form.Group>


        {/*  Show Zero Stock */}
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3}>Show Zero Stock</Form.Label>
          <Col sm={9}>
            <Switch defaultChecked onChange={() => setShowZeroStock(!showZeroStock)} checked={showZeroStock} />
          </Col>
        </Form.Group>


        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3}>TVA %</Form.Label>
          <Col sm={9}>
            <Form.Control value={tva} onChange={(e) => setTva(e.target.value)} type="number" step='any' placeholder="%" style={{ width: '70%' }} />
          </Col>
        </Form.Group>

        {/* default Tva */}
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3}>Default Use TVA</Form.Label>
          <Col sm={9}>
            <Switch defaultChecked onChange={() => setDefaultTva(!defaultTva)} checked={defaultTva} />
          </Col>
        </Form.Group>


        {/* Print Direct */}
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3}>Print Direct</Form.Label>
          <Col sm={9}>
            <Switch defaultChecked onChange={() => setPrintDirect(!printDirect)} checked={printDirect} />
          </Col>
        </Form.Group>

        {/* language */}
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
          <Form.Label column sm={3}>
            language
          </Form.Label>
          <Col sm={9}>
            <select className="form-control" style={{ width: '70%' }} value={language} onChange={(e) => setLanguage(e.target.value)}>
              <option value={'english'}>English</option>
              <option value={'arabic'}>Arabic</option>
            </select>
          </Col>
        </Form.Group>

        {/* Cost Changer Method */}
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
          <Form.Label column sm={3}>
            Cost Changer Method
          </Form.Label>
          <Col sm={9}>
            <select className="form-control" style={{ width: '70%' }} value={costChangerMethod} onChange={(e) => setCostChangerMethod(e.target.value)}>
              <option value='StockValue'>Stock Value</option>
              <option value='AverageCost'>Average Cost</option>
            </select>
          </Col>
        </Form.Group>


        {/* Money Exchange */}
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3}>Money Exchange</Form.Label>
          <Col sm={9}>
            <Switch defaultChecked onChange={() => setMoneyExchange(!moneyExchange)} checked={moneyExchange} />
          </Col>
        </Form.Group>

        {/* Currency */}
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3}>Currency</Form.Label>
          <Col sm={9}>
            <Select
              className='dropDownSettings'
              options={currencyOptions}
              isSearchable={true}
              placeholder="Select"
              value={currency}
              onChange={setCurrency}
            />
          </Col>
        </Form.Group>



        {/* use shipping */}
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3}>isRated</Form.Label>
          <Col sm={9}>
            <Switch defaultChecked onChange={() => setIsRated(!isRated)} checked={isRated} />
          </Col>
        </Form.Group>


        {/* Rate */}

          <div>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>Rate</Form.Label>
              <Col sm={9}>
                <Form.Control value={rate} onChange={(e) => setRate(e.target.value)} type="number" step='any' placeholder="Rate" style={{ width: '70%' }} />
              </Col>
            </Form.Group>


            {/* Rating Formula */}
            <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
              <Form.Label column sm={3}>
              Rating Formula
              </Form.Label>
              <Col sm={9}>
                <select className="form-control" style={{ width: '70%' }} value={rateMultiply} onChange={(e) => setRateMultiply(e.target.value == 'true')}>
                  <option value={'true'}>Multiply</option>
                  <option value={'false'}>Divide</option>
                </select>
              </Col>
            </Form.Group>


            {/* Rated Currency */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>Rated Currency</Form.Label>
              <Col sm={9}>
                <Select
                  className='dropDownSettings'
                  options={currencyOptions}
                  isSearchable={true}
                  placeholder="Select"
                  value={ratedCurrency}
                  onChange={setRatedCurrency}
                />
              </Col>
            </Form.Group>
          </div>
        

        {/* use shipping */}
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3}>Use Shipping</Form.Label>
          <Col sm={9}>
            <Switch defaultChecked onChange={() => setUseShipping(!useShipping)} checked={useShipping} />
          </Col>
        </Form.Group>


        {/* shipping Cost  */}
        {useShipping &&
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>Shipping Cost</Form.Label>
            <Col sm={9}>
              <Form.Control value={shippingCost} onChange={(e) => setShippingCost(e.target.value)} type="number" step='any' placeholder="Rate" style={{ width: '70%' }} />
            </Col>
          </Form.Group>
        }


        {/* shipping Rate  */}
        {useShipping &&
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>Shipping Rate</Form.Label>
            <Col sm={9}>
              <Form.Control value={shippingRate} onChange={(e) => setShippingRate(e.target.value)} type="number" step='any' placeholder="Rate" style={{ width: '70%' }} />
            </Col>
          </Form.Group>
        }



        <Form.Group as={Row} className="mb-3">
          <Col sm={{ span: 10, offset: 2 }}>
            <Button type="submit" className="btn btn-primary">Save</Button>
          </Col>
        </Form.Group>
      </Form>


    </div>
  );
}

export default GeneralSetting;
