const ExcelJS = require('exceljs');
const FileSaver = require('file-saver');

const exportToExcel = (data, fileName) => {

    console.log(data)
    // Check if data is provided and is an array with at least one element
    if (!data || !Array.isArray(data) || data.length === 0) {
        console.error('Invalid data provided for exporting to Excel.');
        return; // Exit function if data is invalid
    }

    console.log('Starting exportToExcel function with data:', data, 'and fileName:', fileName);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Example headers based on the keys of the first object in data
    const headers = Object.keys(data[0]);

    // Add headers to the worksheet
    worksheet.columns = headers.map(header => ({
        header,
        key: header,
        width: 15 // Example width, adjust as needed
    }));

    // Add data rows to the worksheet
    data.forEach(item => {
        worksheet.addRow(item);
    });

    // Return the promise from writeBuffer() to handle the buffer
    return workbook.xlsx.writeBuffer().then(buffer => {
 
        // Convert buffer to Blob and save/download using FileSaver
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, `${fileName}.xlsx`);
        
        console.log(`Excel file ${fileName}.xlsx saved/downloaded successfully.`);
    }).catch(err => {
        console.error('Error exporting Excel:', err);
        throw err; // Optionally rethrow or handle the error
    });
};

module.exports = { exportToExcel };
