import React, { useState, useEffect, useContext } from 'react';


import SimpleTable from '../../components/tables/simpleTable';

import TrashModal from '../../components/modals/trashModal';
import RecoverModal from '../../components/modals/recoverModal';
import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';
import DeleteModal from '../../components/modals/deleteModal';

import SearchFilter from '../../components/filters/search';
import CustomButton from '../../components/buttons/customButton';
import IsActiveFilter from '../../components/filters/isActive';
import AddButton from '../../components/buttons/addButton';
import DataFilter from '../../components/filters/dataFilter';
import DatePicker from '../../components/filters/datePicker';

import { FaPlus } from "react-icons/fa";

import Loader from '../../components/loader/loader';
import { numberFormatter, totalFormatter } from '../../components/formatters/numberFormatter';
import { dateFormatter } from '../../dynamic/dateFormatter';
import { clientOrderColumnDefs } from '../../components/tables/columnDefs';
import toast from 'react-hot-toast';

import { Space, Input, Tag, Button, Tabs } from 'antd';


import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcViewDetails, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { IoBarcodeOutline } from "react-icons/io5";
import { FcPlus } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";

import { DataContext } from '../../init/getData';




function Product(props) {

  let { } = props;


  const { userData, settingData, serverApi, api, refCategory, categoryData } = useContext(DataContext);
  const userRole = userData.role;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const createdBy = parseInt(userData.id);



  useEffect(() => {
    const fetchData = async () => {
      try {
        await refCategory();
        setIsLoading(false);
      } catch (error) {
        console.error('Error refreshing data Contact Support');
      }
    };
    fetchData();
  }, []);


  // Category Options
  const categoryOptions = categoryData.map(item => ({
    value: item.id,
    label: item.name,
  }));

  categoryOptions.push({ value: null, label: 'All Categories' })


  // Category Options
  const typeOptions = [
    { value: null, label: 'All Types' },
    { value: 'simple', label: 'simple' },
    { value: 'serialized', label: 'serialized' },
  ]


  const today = new Date().toISOString().split('T')[0];

  const [idRow, setIdRow] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState({ search: '', isActive: 'true', categoryId: null, type: null });

  const [showAddModal, setShowAddModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);


  const [productUnits, setProductUnits] = useState([]);
  const [category, setCategory] = useState([]);
  const [type, setType] = useState('');
  const [product, setProduct] = useState({});

  // idRow Change
  useEffect(() => {
    const categoryId = categoryOptions.find(option => option.value === idRow?.categoryId);
    setCategory(categoryId);
    setProduct(
      {
        createdBy: createdBy,
        type: idRow?.type || type,
        name: idRow?.name,
        barcode: idRow?.barcode,
        categoryId: categoryId?.value || filter.categoryId,
        sku: idRow?.sku,
        isStock: idRow?.isStock,
        isSale: idRow?.isSale,
        isTva: idRow?.isTva,
        cost: idRow?.cost || 0,
        price: idRow?.price || 0,
        wholeSalePrice: idRow.wholeSalePrice || 0,
        productUnits: idRow?.productUnits,
        color: idRow?.color || "#b6e3f4",
        details: idRow?.details,
        unit: idRow?.unit || 'Pcs',
      }
    )
    setProductUnits(idRow?.units || [])
  }, [idRow]);

  const table = 'products';
  const title = 'Products';

  const handleAddVarientRow = () => {
    setProductUnits([...productUnits, { unit: '', value: '', productId: idRow?.id }]);
  };

  // Delete VarientRow
  const handleDeleteVarientRow = (event) => {
    event.preventDefault()
    if (productUnits.length > 0) {
      const updatedRows = productUnits.slice(0, -1);
      setProductUnits(updatedRows);
    } else {
      toast.error('you need at least one variant')
    }
  };


  const handleUnitChange = (index, field, value) => {
    const updatedUnits = [...productUnits];
    const parsedValue = field === 'value' ? parseFloat(value) : value;
    updatedUnits[index] = { ...updatedUnits[index], [field]: parsedValue };
    setProductUnits(updatedUnits);
  }


  const addModal = (type) => {
    setIdRow({});
    setType(type)
    resetForm();
    setShowAddModal(!showAddModal);
  }



  const resetForm = () => {
    setProduct({ createdBy: createdBy, type: type })
    setCategory('')
    setProductUnits([])
  }


  // Generic handler for input changes
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (value === 'true' || value === 'false') {
      value = JSON.parse(value)
    }
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption, name) => {
    setCategory(selectedOption)
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: selectedOption.value,
    }));
  };


  const refreshTableFunction = () => {
    setRefreshTable(!refreshTable)
  }

  const recoverModal = (e) => {
    if (!showRecoverModal) {
      setIdRow(e);
    }
    setShowRecoverModal(!showRecoverModal);
  }

  const trashModal = (e) => {
    if (!showTrashModal) {
      setIdRow(e);
    }
    setShowTrashModal(!showTrashModal);
  }

  const deleteModal = (e) => {
    if (!showDeleteModal) {
      setIdRow(e);
    }
    setShowDeleteModal(!showDeleteModal);
  }

  const editModal = (e) => {
    if (!showEditModal) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }

  //Excel Data
  const getExcelData =  (data) => {

    const transformedData = [];
    data.forEach(item => {
      
      const newItem = {
        id: item.id,
        barcode: item.barcode,
        category: item.category?.name,
        name: item.name,
        stock: item.stock,
        unit: item.unit,
        cost: item.cost,
        price: item.price,
        type: item.type,
      };
  
      transformedData.push(newItem);
    });
  
    return transformedData;
  };

  let newColumnDefs = [
    { title: 'barcode', dataIndex: 'barcode' },
    { title: 'category', dataIndex: 'category', render: (category) => (category?.name) },
    { title: 'name', dataIndex: 'name' },
    { title: 'stock', dataIndex: 'totalStock', width: '8%', render: (totalStock, record) => (record.isStock ? totalStock : '--') },
    { title: 'unit', dataIndex: 'unit', width: '8%' },
    { title: 'cost', dataIndex: 'cost', width: '8%' },
    { title: 'price', dataIndex: 'price', width: '8%' },
    { title: 'wsPrice', dataIndex: 'wholeSalePrice', width: '8%' },
    { title: 'type', dataIndex: 'type', width: '10%' },
  ];

  useEffect(() => {

    if (filter.isActive == 'true') {
      newColumnDefs.push(
        {
          title: 'Edt',
          width: '5%',
          render: (_, record) => (
            <FiEdit size={16} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => editModal(record)} />
          ),
        },
        {
          title: 'Del',
          width: '5%',
          render: (_, record) => (
            <BiSolidTrash size={18} style={{ cursor: 'pointer', color: '#e6434f' }} onClick={() => trashModal(record)} />
          ),
        }
      );
    } else {
      newColumnDefs.push({
        title: 'Rec',
        width: '5%',
        render: (_, record) => (
          <FcUndo size={20} style={{ cursor: 'pointer' }} onClick={() => recoverModal(record)} />
        ),
      });
    }
    setColumnDefs(newColumnDefs);

  }, [filter.isActive]);

  const formView = (
    <div style={{ userSelect: 'none' }}>

      <Tabs
        defaultActiveKey="1"
        centered
        items={[
          {
            label: `General`,
            key: 1,
            children:

              <>

                <input name="units" value={productUnits} readOnly hidden></input>

                {/* BarCode  */}
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label className='formLabel'><IoBarcodeOutline size={25} /> Barcode </Form.Label>
                    <Form.Control type="text" name='barcode' placeholder="optional"
                      value={product?.barcode || ''}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  {/* Name  */}
                  <Form.Group as={Col}>
                    <Form.Label className='formLabel'>Name<span style={{ color: 'red' }}> *</span></Form.Label>
                    <Form.Control type="text" name='name'
                      value={product?.name || ''}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>

                  {/* Sku  */}
                  {(filter.categoryId && !idRow.categoryId) &&
                    <Form.Group as={Col}>
                      <Form.Label className='formLabel'>SKU</Form.Label>
                      <Form.Control type="text" name="sku"
                        value={product?.sku || ''}
                        onChange={handleInputChange} />
                    </Form.Group>
                  }

                </Row>

                <Row className="mb-3" style={{ marginTop: '4%' }}>

                  {/* Category  */}
                  {(!filter.categoryId || idRow.categoryId) &&
                    <Form.Group as={Col}>
                      <Form.Label className='formLabel'>Category<span style={{ color: 'red' }}> *</span></Form.Label>
                      <Select
                        style={{ width: '100px' }}
                        options={categoryOptions.filter(option => option.value !== null)}
                        isSearchable={true}
                        placeholder="Select"
                        value={category}
                        onChange={e => handleSelectChange(e, 'categoryId')}
                        required
                      />
                    </Form.Group>
                  }


                  {/* Sku  */}
                  {(!filter.categoryId || idRow.categoryId) &&
                    <Form.Group as={Col}>
                      <Form.Label className='formLabel'>SKU</Form.Label>
                      <Form.Control type="text" name="sku"
                        value={product?.sku || ''}
                        onChange={handleInputChange} />
                    </Form.Group>
                  }


                </Row>

                <Row className="mb-3" style={{ marginTop: '2%' }}>

                  {/* Sale */}
                  <Form.Group as={Col}>
                    <Form.Label className='formLabel'>Salable<span style={{ color: 'red' }}> *</span></Form.Label>
                    <select className="form-control" name='isSale'
                      value={product?.isSale}
                      onChange={handleInputChange}>
                      <option value='true'>yes</option>
                      <option value='false'>no</option>
                    </select>
                  </Form.Group>


                  {/* Stockable */}
                  <Form.Group as={Col}>
                    <Form.Label className='formLabel'>Stockable<span style={{ color: 'red' }}> *</span></Form.Label>
                    <select className="form-control" name='isStock'
                      value={product?.isStock}
                      onChange={handleInputChange}>
                      <option value='true'>yes</option>
                      <option value='false'>no</option>
                    </select>
                  </Form.Group>
                </Row>

                <Row className="mb-3" style={{ marginTop: '4%' }}>

                  {/* Cost */}
                  <Form.Group as={Col}>
                    <Form.Label className='formLabel'>Cost<span style={{ color: 'red' }}> *</span></Form.Label>
                    <Form.Control type="number" step="any" name='cost'
                      value={product?.cost}
                      onChange={handleInputChange} />
                  </Form.Group>

                  {/* WholeSalePrice */}
                  {userRole.wholeSale &&
                    <Form.Group as={Col} >
                      <Form.Label className='formLabel'>WholeSale Price</Form.Label>
                      <Form.Control type="number" name="wholeSalePrice"
                        value={product?.wholeSalePrice}
                        onChange={handleInputChange} />
                    </Form.Group>
                  }


                  {/* Price */}
                  <Form.Group as={Col} >
                    <Form.Label className='formLabel'>Price<span style={{ color: 'red' }}> *</span></Form.Label>
                    <Form.Control type="number" name="price"
                      value={product?.price}
                      onChange={handleInputChange} />
                  </Form.Group>
                </Row>

                <Row className="mb-3" style={{ marginTop: '2%' }}>

                  {/* Details */}
                  <Form.Group as={Col} sm={6}>
                    <Form.Label className='formLabel'>Details</Form.Label>
                    <Form.Control as="textarea" name="details"
                      value={product?.details || ''}
                      onChange={handleInputChange} />
                  </Form.Group>

                  {/* isTva */}
                  <Form.Group as={Col}>
                    <Form.Label className='formLabel'>TVA<span style={{ color: 'red' }}> *</span></Form.Label>
                    <select className="form-control" name='isTva'
                      value={product?.isTva}
                      onChange={handleInputChange}>
                      <option value='true'>yes</option>
                      <option value='false'>no</option>
                    </select>
                  </Form.Group>


                  <Form.Group as={Col}>
                    <Form.Label className='formLabel' htmlFor="exampleColorInput">Box Color</Form.Label>
                    <Form.Control
                      type="color"
                      name="color"
                      title="Choose your color"
                      value={product.color}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Row>
              </>


          },
          ...(settingData.useSecondaryUnit ? [{
            label: `Units`,
            key: 2,
            children: (
              <>
                <Row className="mb-3">

                  {/* Product Unit  */}
                  <Form.Group as={Col}>
                    <Form.Label className='formLabel'>Product Unit</Form.Label>
                    <Form.Control type="text" name="unit"
                    value={product?.unit}
                    onChange={handleInputChange} />
                  </Form.Group>

                </Row>

                {productUnits.map((row, index) => (
                  <div key={index}>
                    {/* Render the labels only for the first row */}
                    {index === 0 && (
                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Form.Label className='formLabel'>Unit</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label className='formLabel'>Value</Form.Label>
                        </Form.Group>
                      </Row>
                    )}

                    <Row className="mb-3" style={{ marginTop: '-20px' }}>

                      <Form.Group as={Col}>
                        <Form.Control type="text" name="unit"
                          onChange={(e) => handleUnitChange(index, 'unit', e.target.value)}
                          value={row.unit} />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Control type="number" step="any" name="value"
                          onChange={(e) => handleUnitChange(index, 'value', e.target.value)}
                          value={row.value} />
                      </Form.Group>



                    </Row>

                    <Row key={index + 100} className="mb-3">

                    </Row>
                  </div>
                ))}

                <Form.Group
                  as={Col}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <FcPlus size={26} style={{ cursor: 'pointer' }} onClick={handleAddVarientRow} />
                  <HiMinusCircle size={28} style={{ cursor: 'pointer' }} onClick={(e) => handleDeleteVarientRow(e)} />
                </Form.Group>
              </>
            )
          }] : [])
        ]}

      />


    </div >);




  const modalFooter = (
    <div>
      {type}
    </div>
  );

  const tableFooter = (
    <></>
  );


  return (
    <div className='card'>

      {isLoading &&
        <Loader />
      }


      {!isLoading &&
        <>
          <div>

            <SearchFilter setFilter={setFilter} />
            <IsActiveFilter setFilter={setFilter} />

            <DataFilter setFilter={setFilter} dataOptions={categoryOptions} filterBy={'categoryId'} placeHolder={'All Categories'} />



            <div className='dropDown-btn'>
              <DropdownButton title="Add New" variant="success" className="custom-dropdown-button">
                {settingData.simpleProduct && (<Dropdown.Item onClick={() => addModal('simple')}>Simple</Dropdown.Item>)}
                {settingData.serializedProduct && (<Dropdown.Item onClick={() => addModal('serialized')}>Serialized</Dropdown.Item>)}
                {settingData.variableProduct && (<Dropdown.Item onClick={() => addModal('variable')}>Variable</Dropdown.Item>)}
                {settingData.compoundProduct && (<Dropdown.Item onClick={() => addModal('compound')}>Compound</Dropdown.Item>)}
              </DropdownButton>
            </div>

            <DataFilter setFilter={setFilter} dataOptions={typeOptions} filterBy={'type'} placeHolder={'All Types'} />

          </div>



          <SimpleTable
            filter={filter}
            showAddModal={showAddModal}
            addModal={addModal}
            table={table}
            title={title}
            columnDefs={columnDefs}
            idRow={idRow}
            setIdRow={setIdRow}
            refreshTable={refreshTable}
            data={data}
            setData={setData}
            tableFooter={tableFooter}
            api={api}
            getExcelData={getExcelData}
          />

          <TrashModal
            showModal={showTrashModal}
            toggleModal={trashModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            data={{ id: idRow.id }}
          />

          <DeleteModal
            showModal={showDeleteModal}
            toggleModal={deleteModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <RecoverModal
            showModal={showRecoverModal}
            toggleModal={recoverModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <AddModal
            showModal={showAddModal}
            toggleModal={addModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'lg'}
            resetForm={resetForm}
            footer={modalFooter}
            api={api}
            data={{ product, productUnits }}
          />

          <EditModal
            showModal={showEditModal}
            toggleModal={editModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'lg'}
            footer={modalFooter}
            api={api}
            data={{ id: idRow?.id, product, productUnits }}
          />


        </>
      }

    </div>
  )
}

export default Product;
