import React, {  useState, useEffect, createContext} from 'react';
import { Route, Routes, useNavigate, NavLink} from 'react-router-dom';


import AppMode from './appMode/appMode';

import UserHeader from './user/userHeader';
import RoleHeader from './role/roleHeader';
import PrinterHeader from './printer/printerHeader';
import ScaleHeader from './scale/scaleHeader';
import License from './license/license';

import { FcSupport, FcManager, FcExport  , FcReadingEbook, FcPrint, FcTimeline } from "react-icons/fc";

function DevTab(props) {

  const [activeTab, setActiveTab] = useState('appMode');

  const navigate = useNavigate();

  useEffect(() => {

    navigate('/devTab/appMode');

  }, []);


  return (
    <div className="card" style={{paddingTop : '0px', userSelect: 'none'}}>
      
      <div>

        <NavLink to="appMode"  onClick={() => setActiveTab('appMode')}>
        <div className={`${activeTab === 'appMode' ? 'tab-two active-link' : 'tab-two'}`}>
          <FcSupport size={35}/>
            <span className="tab-text">App Mode</span>
          </div>
        </NavLink>
        
        <NavLink to="user" className={({isActive}) => (isActive ? "active-user" : 'none')}>
          <div className="tab-two" style={{width: '12%'}}>
          <FcManager size={35}/>
            <span className="tab-text">Services</span>
          </div>
        </NavLink>

        <NavLink to="role" className={({isActive}) => (isActive ? "active-user" : 'none')}>
          <div className="tab-three" style={{width: '12%'}}>
          <FcReadingEbook size={35}/>
            <span className="tab-text">Permissions</span>
          </div>
        </NavLink>
    
      
      <NavLink to="printer" className={({isActive}) => (isActive ? "active-user" : 'none')}>
          <div className="tab-four" style={{width: '12%'}}>
          <FcPrint size={35}/>
            <span className="tab-text">Printer</span>
          </div>
        </NavLink>

              
      <NavLink to="scale" className={({isActive}) => (isActive ? "active-user" : 'none')}>
          <div className="tab-five" style={{width: '12%'}}>
          <FcTimeline size={35}/>
            <span className="tab-text">Scale</span>
          </div>
        </NavLink>

        <NavLink to="license" className={({isActive}) => (isActive ? "active-user" : 'none')}>
          <div className="tab-six" style={{width: '12%'}}>
          <FcTimeline size={35}/>
            <span className="tab-text">License</span>
          </div>
        </NavLink>

     


      </div>

      

 
      <Routes>

        <Route path='appMode' element= {<AppMode/>}/>
        
        <Route path='user' element= {<UserHeader/>}/>
        <Route path='role' element= {<RoleHeader/>}/>
        <Route path='printer' element= {<PrinterHeader/>}/>
        <Route path='scale' element= {<ScaleHeader/>}/>
        <Route path='license' element= {<License/>}/>
        
      </Routes>

    </div>
  );
}

export default DevTab;
