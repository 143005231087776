
//const link = 'http://localhost:5001/api';

const link = 'https://admin.bakeryalhadi.com:5011/api';

//const link = 'https://accounting.eastmedsolutions.com:5007/api';
//const link = 'https://fashionsista.riserteam.com:5010/api';

//const link = 'http://localhost:5001/api';


export  { link};