import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import Select from 'react-select';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { DataContext } from '../../../init/getData';

const AccountEdit = (props) => {

  const { api, userData, refreshAccount } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const {showModal, toggleModal, idRow, refreshTable, type, setEditedCustomer} = props;
  const [loadingEffect, setLoadingEffect] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingEffect(true);
    const form = document.getElementById("editForm");
      
    const formData = new FormData(form);
    formData.append("id", idRow.id)
    const data = Object.fromEntries(formData);
    
    let res = await api.post(`/account/edit`, JSON.stringify(data))
    if(res.data.status){
      toast.success(res.data.message);
      refreshAccount();
      toggleModal();
    }else{
      toast.error(res.data.message);
    }

    form.reset();
    if(refreshTable){
      refreshTable();
    }

    if(setEditedCustomer){
      let editedCustomer = {
        value: idRow.id,
        id: idRow.id,
        name: data['name'],
        label:  data['name'] + ' [' + data['phone']+ ']' + ' [' +data['phone2']+ ']',
        phone: data['phone'],
        phone2: data['phone2'],
        address: data['address'],
        address2: data['address2'],
      }
      setEditedCustomer(editedCustomer)
    }
    setLoadingEffect(false);
  }
 
    return (
      <Modal show={showModal} backdrop="static" size="lg" onHide={toggleModal}>
        <Modal.Header style={{ background: "#1f2937" }}>
          <Modal.Title style={{ color: "white" }}>Edit {type}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={handleSubmit} id='editForm'>


      <Row className="mb-3" style={{ marginTop: '2%' }}>

      <Form.Group as={Col}>
          <Form.Label>Name</Form.Label>
          <input type="text" defaultValue={idRow.name}  name="name" id="name" className="form-control"></input>
        </Form.Group>

      </Row>


      <Row className="mb-3" style={{ marginTop: '2%' }}>

      <Form.Group as={Col}>
          <Form.Label>Phone</Form.Label>
          <input type="text" defaultValue={idRow.phone}  name="phone" id="phone" className="form-control"></input>
        </Form.Group>


        <Form.Group as={Col}>
          <Form.Label>Phone2</Form.Label>
          <input type="text" defaultValue={idRow.phone2}  name="phone2" id="phone2" className="form-control"></input>
        </Form.Group>

      </Row>



      <Row className="mb-3" style={{ marginTop: '2%' }}>

<Form.Group as={Col}>
    <Form.Label>Address</Form.Label>
    <textarea className="form-control" defaultValue={idRow.address}  name="address" id="address"></textarea>
  </Form.Group>


  <Form.Group as={Col}>
    <Form.Label>Address2</Form.Label>
    <textarea className="form-control" defaultValue={idRow.address2} name="address2" id="address2"></textarea>
  </Form.Group>

</Row>
        
  
<Row className="mb-3" style={{ marginTop: '2%' }}>
<Form.Group as={Col}>
    <Form.Label>Details</Form.Label>
    <textarea className="form-control" defaultValue={idRow.details} name="details" id="details"></textarea>
  </Form.Group>

</Row>


<div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <button className="btn btn-primary" onClick={handleSubmit}>Save Data
        {loadingEffect && 
        <span className="spinner-border spinner-border-sm" style={{ marginLeft: '10px'}} role="status" aria-hidden="true"></span>
          }
        </button>

      </div>

      </Form>
        </Modal.Body>
      </Modal>
    );
  };
  
  export default AccountEdit;
