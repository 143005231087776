import React, {  useState, useEffect, createContext} from 'react';
import { Route, Routes, useNavigate, NavLink} from 'react-router-dom';


import GeneralSetting from './generalSettings';
import UserHeader from './user/userHeader';
import RoleHeader from './role/roleHeader';
import License from './license/license';
import PiSetting from './piSetting/piSetting';
import ProductSetting from './productSetting/productSetting';

import { FcSupport, FcManager, FcPackage, FcReadingEbook, FcPrint, FcTimeline } from "react-icons/fc";

function SettingTab(props) {



  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('general');

  useEffect(() => {

    navigate('/settingTab/general');

  }, []);


  return (
    <div className="card" style={{paddingTop : '0px', userSelect: 'none'}}>
      
      <div>

        <NavLink to="general" onClick={() => setActiveTab('general')}>
          <div className={`${activeTab === 'general' ? 'tab-one active-link' : 'tab-one'}`} style={{width: '12%'}}>
          <FcSupport size={35}/>
            <span className="tab-text">General</span>
          </div>
        </NavLink>
        
        <NavLink to="user" onClick={() => setActiveTab('user')}>
          <div className={`${activeTab === 'user' ? 'tab-two active-link' : 'tab-two'}`} style={{width: '12%'}}>
          <FcManager size={35}/>
            <span className="tab-text">Users</span>
          </div>
        </NavLink>

        <NavLink to="role" onClick={() => setActiveTab('role')}>
          <div className={`${activeTab === 'role' ? 'tab-three active-link' : 'tab-three'}`} style={{width: '12%'}}>
          <FcReadingEbook size={35}/>
            <span className="tab-text">Roles</span>
          </div>
        </NavLink>
    
      
      <NavLink to="purchaseInvoice" onClick={() => setActiveTab('purchaseInvoice')}>
          <div className={`${activeTab === 'purchaseInvoice' ? 'tab-four active-link' : 'tab-four'}`} style={{width: '12%'}}>
          <FcPrint size={35}/>
            <span className="tab-text">Purchase Invoice</span>
          </div>
        </NavLink>

        <NavLink to="products" onClick={() => setActiveTab('products')}>
          <div  className={`${activeTab === 'products' ? 'tab-one active-link' : 'tab-one'}`}>
          <FcPackage size={33}/>
            <span className="tab-text">Products</span>
          </div>
        </NavLink>


              
      <NavLink to="scale" onClick={() => setActiveTab('scale')}>
          <div className={`${activeTab === 'scale' ? 'tab-five active-link' : 'tab-five'}`} style={{width: '12%'}}>
          <FcTimeline size={35}/>
            <span className="tab-text">Scale</span>
          </div>
        </NavLink>

        <NavLink to="license" onClick={() => setActiveTab('license')}>
          <div className={`${activeTab === 'license' ? 'tab-six active-link' : 'tab-six'}`} style={{width: '12%'}}>
          <FcTimeline size={35}/>
            <span className="tab-text">License</span>
          </div>
        </NavLink>

   

     


      </div>

      
    

 
      <Routes>

        <Route path='general' element= {<GeneralSetting/>}/>
        <Route path='purchaseInvoice' element= {<PiSetting/>}/>
        <Route path='products' element= {<ProductSetting/>}/>
        <Route path='user' element= {<UserHeader/>}/>
        <Route path='role' element= {<RoleHeader/>}/>
        <Route path='license' element= {<License/>}/>
        
      </Routes>

    </div>
  );
}

export default SettingTab;
