import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import Select from 'react-select';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { DataContext } from '../../../init/getData';

const UserEdit = (props) => {

  const { api, userData, refreshAllUserData} = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const {showModal, toggleModal, idRow, refreshTable, tittle, setEditedCustomer} = props;
  const {refreshCustomer, roleData} = useContext(DataContext);

  const [role, setRole] = useState({value: '', label: ''});

  useEffect(() => {
    if(idRow && idRow.role){
    setRole({value: idRow.role.id, label: idRow.role.name})
    }
  }, [idRow]);

  
      // Category Options for dropDown
      const roleOptions = roleData.map(item => ({
        value: item.id,
        label: item.name,
      }));

 

  const handleSubmit = async (e) => {
    toggleModal();
    e.preventDefault();
    const form = document.getElementById("editForm");
      
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);

    data['id'] = idRow.id; 
    data['roleId'] = role.value; 

    
    let res = await api.post(`/user/edit`, JSON.stringify(data))
    if(res.data.status){
      toast.success(res.data.message);
      refreshTable();
      refreshAllUserData();
      form.reset();
    }else{
      toast.error(res.data.message);
    }

    
  }
 
    return (
      <Modal show={showModal} size="lg" onHide={toggleModal}>
        <Modal.Header style={{ background: "#1f2937" }}>
          <Modal.Title style={{ color: "white" }}>Edit {tittle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={handleSubmit} id='editForm'>


          <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
            <Form.Label>Name</Form.Label>
            <input type="text" defaultValue={idRow.name}  name="name" id="name" className="form-control"></input>
          </Form.Group>

          {/* delivery  */}
          <Form.Group as={Col}>
          <Form.Label>Role</Form.Label>
            <Select
            options={roleOptions}
            isSearchable={true}
            placeholder="Select Role"
            value={role}
            onChange={setRole}
            />
        </Form.Group>

        </Row>


        <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
          <Form.Label>UserName</Form.Label>
          <input type="text" defaultValue={idRow.userName}   name="userName" id="userName" className="form-control"></input>
        </Form.Group>


        <Form.Group as={Col}>
          <Form.Label>Password</Form.Label>
          <input type="text" defaultValue={idRow.password}  name="password" id="password" className="form-control"></input>
        </Form.Group>

        </Row>


      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <input type="submit" className="btn btn-primary" value="Save Data"></input>
      </div>

      </Form>
        </Modal.Body>
      </Modal>
    );
  };
  
  export default UserEdit;
