import React, { useState, useEffect, useContext, createContext } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'antd';

import { DataContext } from '../../init/getData';
import toast from 'react-hot-toast';

import { Route, Routes, useNavigate, NavLink } from 'react-router-dom';

export const selectedMissionContext = createContext();

function EndOfDayModal(props) {


  const navigate = useNavigate();
  const { showModal, toggleModal } = props;
  const [loadingEffect, setLoadingEffect] = useState(false);

  const { devData, localApi, serverApi } = useContext(DataContext);

  const [allOrders, setAllOrders] = useState([]);


  // 1.Get orders
  // 2.Send to Server
  // 3.Delele ordes 

  const getOrders = async () => {
    setLoadingEffect(true);

    let data = {
      filter: {
        type: ['Sale', 'Return'],
        isActive: true,
      },
    };

    try{
    let res = await localApi.get(`/transaction/getall`, { params: data });
    if (res.data.status) {
      const allOrders = res.data.data;
      allOrders.forEach(order => {
        order.cart.forEach(item => {
          item.productId = item.productIdCloud;
        });
      });
      syncToServer(allOrders)
    } else {
      toast.error(res.data.message);
      setLoadingEffect(false);
    }
  }
  catch{
    toast.error('Failed Contact Support');
    setLoadingEffect(false);
  }
  }


  const syncToServer = async (data) => {


    let res = await serverApi.post(`/transaction/syncToServer`, JSON.stringify(data))
    if (res.data.status) {
      deleteAllOrders();
    } else {
      toast.error(res.data.message);
    }
    setLoadingEffect(false);
  }


  const deleteAllOrders = async () => {
  

    let res = await localApi.post(`/transaction/endOfDay`)
    if (res.data.status) {
      setAllOrders(res.data.data);
      navigate('/newSale');
      toast.success('Day Ended Successfully')
      toggleModal();
    } else {
      toast.error(res.data.message);
    }
    setLoadingEffect(false);
  }


  return (
    <Modal show={showModal} onHide={toggleModal} backdrop='static'>
      <Modal.Header style={{ background: "#e6434f" }}>
        <Modal.Title className="arabicText" style={{ color: "white" }}>End of day</Modal.Title>
      </Modal.Header>
      <Modal.Body className="arabicText">
        All orders will be transfered to server, are you sure ?
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button type="primary" size="large" onClick={toggleModal} style={{ backgroundColor: '#6d747d' }} >cancel</Button>
          <Button type="primary" size="large" loading={loadingEffect} onClick={getOrders}
            style={{ marginLeft: '10px' }} danger>Yes</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default EndOfDayModal;
