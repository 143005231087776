import React, { useState, useEffect, useContext  } from 'react';
import Select from 'react-select';
import StockTable from './stockTable';

import { DataContext } from '../../init/getData';

import { FcTodoList, FcDeployment, FcSalesPerformance, FcRightDown } from "react-icons/fc";

function StockHeader(props) {
  
 
  const [isActive, setIsActive] = useState('true');
  const [search, setSearch] = useState('');

  const [categoryId, setCategoryId] = useState({ value: null, label: 'All Categories'});
 
  const { userData, settingData, categoryData, wareHouseData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role;

 
const isActiveChange = (e) => {
    setIsActive(e);
};


const wareHouseOptions = wareHouseData.map(item => ({
  value: item.id,
  label: item.name
}));

const defaultWareHouse = wareHouseOptions.find(option => option.value === parseInt(userRole.defaultWareHouseId));

// Set the initial value of wareHouseId based on defaultWareHouse
const [wareHouseId, setWareHouseId] = useState(defaultWareHouse || {});



const handleSearch = (e) => {
  if (e.key === 'Enter') {
    setSearch(e.target.value)
  }
};

const handleSearchChange = (e) => {
  if(e.target.value == ''){
    setSearch(e.target.value)
  }
};


    // Category Options for dropDown
    const categoryOptions = categoryData.map(item => ({
      value: item.id,
      label: item.name
    }));

  
      categoryOptions.push({
      value: null,
      label: 'All Categories',
    });


  return (
    <div className='card' style={{userSelect: 'none'}}>

    
        <div>
        
        <input type="search" className="form-control search" placeholder="Search" 
        onKeyDown={(e) => handleSearch(e)}
        onChange={(e) => handleSearchChange(e)}>
        </input>

        
        <select name="groupId" className="form-control dropDown" id="groupId" onChange={(e) => isActiveChange(e.target.value)} >
          <option value='true' >Active</option>
          <option value='false'>Deleted</option>
        </select>

        <Select
            className='dropDownCategory'
            options={categoryOptions}
            isSearchable={true}
            placeholder="Select"
            value={categoryId}
            onChange={setCategoryId}
            />


        <Select
            className='dropDown'
            options={wareHouseOptions}
            isSearchable={true}
            placeholder="Select"
            value={wareHouseId}
            onChange={setWareHouseId}
            />
    

      </div>
  
      {wareHouseId.value && (
      <StockTable 
        isActive={isActive}
        search={search}
        wareHouseId={wareHouseId}
        categoryId={categoryId.value}
        />
      )}

    </div>
  )
}

export default StockHeader;
