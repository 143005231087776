import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { DataContext } from '../../../../init/getData';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const SerialStockAdd = (props) => {
  
  const { api, userData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const { showModal, toggleModal, refreshTable, idRow, wareHouseId } = props;
  const {refreshProduct} = useContext(DataContext);

  const [loadingEffect, setLoadingEffect] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingEffect(true);
    const form = document.getElementById("addForm");
      
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);

    data['id'] = idRow.id;  
    data['createdBy'] = userId;  
    data['wareHouseId'] = wareHouseId.value; 

    if (checkFields(formData)) {
      setLoadingEffect(false);
      return; // Stop further execution
    }
    
    let res = await api.post(`/stock/addSerial`, JSON.stringify(data))
    if(res.data.status){
      toast.success(res.data.message);
      refreshProduct();
      form.reset();
      refreshTable();
     
    }else{
      toast.error(res.data.message);
      form.reset();
    }

    setLoadingEffect(false);
  }

  // Check Fields
  const checkFields = (formData) => {
    let check = 0;

    if (formData.get('serial') === '') {
      toast.error("Please write the serial");
      check = 1;
    }

    if(check == 1){
      return true;
    }else{
      return false;
    }
  }


  return (
      <Modal show={showModal} onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Add To Stock</Modal.Title>
      </Modal.Header>
      <Modal.Body>


      <Form onSubmit={handleSubmit} id='addForm'>

        <Form.Group as={Row} className="mb-3" >
        <Form.Label column sm="2"> Serial </Form.Label>
        <Col sm="10">
        <Form.Control type="text" name="serial" id="serial" autoFocus/>
        </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" >
        <Form.Label column sm="2"> Details </Form.Label>
        <Col sm="10">
        <Form.Control as="textarea" name="details" id="details" />
        </Col>
        </Form.Group>
 


      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <button className="btn btn-primary" onClick={handleSubmit}>Save Data
        {loadingEffect && 
        <span className="spinner-border spinner-border-sm" style={{ marginLeft: '10px'}} role="status" aria-hidden="true"></span>
          }
        </button>

      </div>
      </Form>
      </Modal.Body>
      </Modal>
      )
}


export default SerialStockAdd;